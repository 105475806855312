@import '../../../../config.scss';

.modal {
  background: rgba(0, 0, 0, 0.7);
}

.modalTitle {
  text-align: center;
  margin: 10px 15px;
  background-color: $primary;
  color: $white;
  border-radius: 5px;
}

.cropperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  min-width: 150px;
  margin: 15px 20px;
  border-radius: 10px;
}

.buttonExternal {
  min-width: 100px;
  min-height: 40px;
}
