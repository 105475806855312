@import "../../../../config.scss";

.overlay {
  display: block;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.5);
}

.title {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $secondary;
  color: $primary;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.search-form {
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
}

.activeImage {
  background: $primary;
  color: $body-bg;
}

.selectedImage {
  background: $primary_alt;
  color: $body-bg;
}
