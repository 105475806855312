.AvatarView {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -webkit-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-out;
}

.AvatarView_comp {
    position: relative;
    height: 100%;
}

.AvatarView_img {
    display: block;
    height: 100%;
}

.AvatarView_canvas {
    display: block;
    position: absolute;
}

.avatar-box .blankImage {
    position: relative;
    width: auto;
    max-height: 100%;
    left: auto;
    top: auto;
    transform: translate(0, 0);
}

.imgFaceLayers {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 50%;
    left: 0;
    top: 0;
}

.avatar-box .imgFaceLayers img {
    position: absolute;
    left: 33.8078%;
    top: 26.1667%;
    width: 28.4698%;
}

.avatar-box .imgFaceLayers img.head,
.avatar-box .imgFaceLayers img.hair_front,
.avatar-box .imgFaceLayers img.headwear_front,
.avatar-box .imgFaceLayers img.glasses {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
}
