@import "../../../config";

.icon-yes, .icon-no {
  width: 1.5em;
  height: 1.5em;
  display: block;
  margin: 0 auto;
}

.icon-yes {
  color: $green;
}

.icon-no {
  color: $red;
}
