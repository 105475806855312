.maintenanceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.maintenanceLogo {
  width: 80px;
  height: auto;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 120px;
  }
}

.maintenanceMessage {
  font-size: 20px;
  margin: 10px 0;

  @media (min-width: 768px) {
    font-size: 28px;
  }
}

.maintenanceSubmessage {
  font-size: 16px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.maintenanceLink {
  color: #f56969;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.socialContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;

  @media (min-width: 768px) {
    gap: 20px;
  }
}

.iconButton {
  color: #333;
  text-decoration: none;
  transition: transform 0.2s ease;

  &:hover {
    color: #333;
    transform: scale(1.1);
  }
}

.xLogo {
  width: 24px;
  height: 24px;
  object-fit: contain;

  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
}