.wrapper {
  background: transparent url("../../../assets/images/Pixel-2.svg");
  background-size: cover;
}

.previewContainer {
  transform: translate(13%, 8%);
  overflow: hidden; // extra code to make cure CT doesn't overflow preview area
}

.previewContainerDetached {
  transform: translate(0, 0);
  margin: auto;
  padding: 0;
  overflow: hidden; // extra code to make cure CT doesn't overflow preview area
}

:global {
  button {
    // piece of reset.css in order for CT to work
    background: transparent;
    border: 0;
  }
}
