.overlay {
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.5);
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
}
