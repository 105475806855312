@import "src/config";

.iframe {
  display: flex;
  overflow: hidden;
}

.iframeWrapper {
  display: flex;
  margin: auto;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 16px 8px $pink;
  animation: animate 4s linear infinite;
  border-radius: 2px;
  outline: 5px solid purple;
  background: $gray-800;
}


.streamCol {
padding-bottom: 30px;
}

.streamRow {
  text-align: center;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 16px 8px $pink;
  }
  50% {
    box-shadow: 0 0 16px 8px $blue;
  }
  100% {
    box-shadow: 0 0 16px 8px $pink;
  }
}