@import "../../../../../config";

.modalBig {
  max-width: 98%;
  position: fixed;
  margin: 0 1%;
  width: 100%;
  height: 100%;
  padding: 0;
}

.modalBig .modal-dialog,
.modalBig .container {
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modalBig .modal-content {
  height: 100%;
  display: grid;
  grid-template-rows: 3em calc(100% - 6em) 3em;
}

.modalBig.modalNext-active .modal-content {
  grid-template-rows: 3em calc(100% - 9em) 6em;
}

.modalBig.modalPrev-active .modal-content {
  grid-template-rows: 6em calc(100% - 9em) 3em;
}

.modalBig.modalNext-active.modalPrev-active .modal-content {
  grid-template-rows: 6em calc(100% - 12em) 6em;
}

.modalStepList-ol {
  height: 2.5em;
  overflow: hidden;
  overflow-y: scroll;
}

.listLabel {
  font-size: .8em;
  color: #8e908c;
  margin: 0;
}

.deleteModal {
  z-index: 99999;
  background: rgba(0, 0, 0, .2);
}

.indexCol {
  max-width: 6em;
}

ol.branchesList.list-group {
  list-style: decimal !important
}

ul.branchesList.list-group {
  list-style: none !important
}

ol .list-group-item {
  display: list-item !important
}

.branchesList-li .col-form-label,
.tableSubItems .col-form-label,
.tableSubItems h5,
.branchesList-li h5,
.tableSubItems h6,
.branchesList-li h6 {
  font-size: .9em;
  margin-bottom: 0;
}

.sort-item {
  background: #EBECFE;
}

.sort-item div {
  background: transparent !important;
}

.active-item {
  background-color: #EBECFE;
}

.hoverUp:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: palevioletred;
  position: absolute;
  left: 0;
  bottom: 0;
}

.hoverDown:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: palevioletred;
  position: absolute;
  left: 0;
  top: 0;
}

.handle-li {
  position: relative;
}

.dragElm {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #cccccc;
  position: absolute;
  left: -30px;
  top: 5px;
  z-index: 99;
  border-radius: 15px;
  background: #ffffff;
}

.loadingSpinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

.sort-item {
  z-index: 99999;
}

.sf-container {
  display: grid;
  height: 100%;
  grid-template-rows: 6.5em calc(100% - 10em) 3.5em;
}

.sf-header {
  padding: 1em;
  border-bottom: 1px solid #EBECFE;
}

.sf-content {
  padding: 1em;
  overflow: auto;
}

.sf-footer {
  padding: 1em;
  border-top: 1px solid #EBECFE;
}

.smf-footer, .smf-header {
  justify-content: flex-start;
  align-items: flex-start;
  padding: .5em !important;
}

.sf-modal {
  display: block;
  height: 100%;
  width: 100%;
}

.sortItem {
  background: #EBECFE;
  z-index: 99999;
  cursor: move;
}

.sortItem div {
  background: transparent !important;
}

.boxSelectStepsCopy {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
  }

  .custom-control {
    position: relative;
    z-index: 1;
  }
}

.characterExpressionDropdown {
  .dropdown-toggle {
    width: 100%;
    text-align: left;
    position: relative;
    background: $white;
    color: $dark;
    border-color: $gray-400;

    &:after {
      position: absolute;
      right: .5em;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .dropdown-menu {
    min-width: 28em;
    max-height: 30vh;
    padding: 0 !important;
    overflow: hidden;

    .previewActiveScroll {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .row {
      margin: 0 !important;
    }

    .col {
      padding: 0 !important;
      max-height: 30vh;
    }

    .previewActive {
      height: 0;
      width: 100%;
      padding-top: 100%;
      position: relative;
      background: $gray-200;
      overflow: hidden;

      canvas {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-47.5%, -10%);
        width: 210%;
        height: 300%;
      }
    }

    &.show {
      /* display: flex;
       font-size: .8em;*/
    }
  }
}

