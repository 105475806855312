@import "src/config";

.button {
  border: none;
  outline: none !important;

  &:hover, button:focus, button:active {
    text-decoration: underline !important;
    border: none;
  }
}
