@use "config";

.row {
  width: 100%;
  border: 1px solid #e0e0e0;
  margin: 0;
  border-left: 5px solid transparent;
  &:hover {
    background-color: #f8f8f8;
  }
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.changed {
   border-left: 5px solid config.$primary
}


.checkWrapper {
  display: flex;
  justify-content: flex-end;
}

.grantDelaySecondsText {
  align-self: center;
}
