@import "src/config";

.modalHeader {
  margin: 10px;
  padding: 10px;
  text-align: center;
  background-color: lighten($secondary,10);
  border-radius: 5px;
}

.modalTitle {
  width: 100%;
}

.modalBody {
  display: flex;
  justify-content: center;
}

.audioTitle {
  color: $primary;
  font-weight: bold;
  background-color: $gray-200;
  text-align: center;
}

.audioText {
  margin-top: 30px;
  font-weight: bold;
  text-align: center;
}

.button {
  min-width: 90px;
  margin-left: 20px;
}
