@import "./src/config";

.AvatarDropdownGroupsDropdown {
  .dropdown-toggle {
    display: block;
    width: 100%;
    text-align: left;
    border-radius: 2px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: $gray-800 !important;
    background-color: $white !important;
    background-clip: padding-box;
    border: 1px solid $gray-400 !important;
    position: relative;

    &.disabled {
      background-color: $gray-300;
    }

    &:after {
      position: absolute;
      right: .5em;
      top: 50%;
      transform: translate(-50%, 0);
    }
  }

  .dropdown-menu {
    &.show {
      width: 100%;
    }
  }


  &.disabled {
    .dropdown-toggle {
      background-color: $gray-300 !important;
    }
  }

}

@media (min-width: 769px) {
  .containerAvatarTool .box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 1.5em 1em;
    min-height: 320px;
    max-width: 1300px;
    margin: 0 auto;
  }

  .containerAvatarTool .box > * {
    flex: 1 1 160px;
  }
}

.containerAvatarTool {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.containerAvatarTool .avatarContainer {
  flex-grow: 1;
  min-width: 200px;
}

.avatar-box {
  background: #FAEAE7;
  border-radius: 10px;
  width: 100%;
  height: 0;
  padding-top: 142%;
  margin-bottom: 1em;
}

.forced-avatar-box h6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #B43434;
  padding: .5em;
  z-index: 99;
}

.forced .avatar-box {
  padding-top: 87%;
}

#config-box {
  height: 100%;
  width: 100%;
  padding: 0 25px;
}

.containerAvatarTool .itemContainer {
  flex-grow: 3;
  min-width: 200px;
  width: 100%;
}

.containerAvatarTool .view {
  height: 65vh;
  max-height: 539px;
}

.containerAvatarTool tr {
  user-select: none;
  table-layout: fixed;
}

.containerAvatarTool table td {
  width: 50%;
}

.containerAvatarTool .tables {
  display: flex;
  flex-direction: row;
}

.containerAvatarTool .table {
  padding: 7px;
  border-spacing: 70px 15px;
  border-collapse: separate;
}

.containerAvatarTool .tableColumn {
  width: 50%;
}

.containerAvatarTool figure {
  margin: 0;
}

.containerAvatarTool .button1 {
  padding: 0 1.7vw;
  color: #FFF;
  font-size: 27px;
  background: rgb(133, 164, 173);
  border-radius: 10px;
  margin-top: 5px;
}

.containerAvatarTool .button1:hover {
  background: turquoise;
}

.containerAvatarTool .button2 {
  padding: 0 1.7vw;
  color: #000;
  font-size: 27px;
  text-align: center;
  background: #EE8476;
  border-radius: 10px;
}

.containerAvatarTool .category-button {
  text-align: center;
  background: #EE8476;
  border-radius: 10px;
}

.containerAvatarTool .button2:hover, .containerAvatarTool .category-button:hover {
  background: #f3a79d;
}

.containerAvatarTool .clothing-buttons {
  display: flex;
}

.containerAvatarTool .active-button {
  color: #000;
  border-bottom: none !important;
}

#itemPicker-view {
  width: 100%;
  overflow: hidden;
}

.containerAvatarTool .itemPicker-header {
  text-align: center;
  padding: 0 15px;
  margin: 0 !important;
}

.containerAvatarTool .itemPicker-header .col {
  padding: 0 !important;
}

#itemPicker-container {
  height: 90%;
  overflow-y: scroll;
}

.containerAvatarTool .item-picker {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  padding: 10px 10px 0 10px;
}

@media (max-width: 600px) {
  .containerAvatarTool .item-picker {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 460px) {
  .containerAvatarTool .item-picker {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 380px) {
  .containerAvatarTool .item-picker {
    grid-template-columns: repeat(2, 1fr);
  }
}

.containerAvatarTool .item-picker-item {
  min-width: 0;
  padding: calc(100% - 0.25rem) 0 0 0 !important;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  background-color: #f8f9fa;
  border: 0 !important;
}

.containerAvatarTool .badge {
  width: 100%;
}

.containerAvatarTool .item-picker-item-border {
  border: 7px solid transparent;
  border-radius: 10px;
  background-color: #f8f9fa;
}

.containerAvatarTool .active-item-picker-item {
  border-color: #EE8476;
}

.containerAvatarTool .canvas-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.containerAvatarTool .button-canvas {
  color: #FFF;
  font-size: 28px;
  background: rgb(133, 164, 173);
  border-radius: 10px;
}

.containerAvatarTool .button-canvas:hover {
  background: rgb(153, 179, 187);
}

.containerAvatarTool {
  .type-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button-section {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
  }
}

.containerAvatarTool .button-types {
  color: #FFF;
  font-size: 28px;
  background: rgb(133, 164, 173);
  border-radius: 10px;
  padding: 0 10px 0 10px;
  margin-right: 15px;
}

.containerAvatarTool .button-types:hover {
  background: rgb(153, 179, 187);
}

.containerAvatarTool .type-section {
  height: 50px;
  margin: 10px 0;
}

.containerAvatarTool .type-buttons > .active-button {
  color: #000;
  background: rgb(171, 195, 199);
  border-bottom: none !important;
}

.containerAvatarTool .tab-section {
  width: 100%;
  height: 30px;
  align-items: flex-end;
  display: flex;
}

.containerAvatarTool .nav {
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.containerAvatarTool .tabs {
  list-style: none;
  padding: 0 0 0 .5em;
  width: 100%;
}

.containerAvatarTool .tab {
  position: relative;
  width: auto;
  min-width: 20%;
  height: 100%;
  border-radius: 10px 3vw 0 0;
  transition: .2s;
}

.modal-body {
  max-width: 100%;
  overflow: auto;
}

.overflow-visible.modal-body {
  overflow: visible !important;
}

.containerAvatarTool .tab:hover {
  z-index: 1;
}

.containerAvatarTool .tabLabel {
  font-size: 1.2em;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 10px 3vw 0 0;
  background: rgb(133, 164, 173);
  color: #FFF;
  text-decoration: none;
  transition: .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .containerAvatarTool .tabLabel {
    font-size: 1em;
  }
}

@media only screen and (max-width: 900px) {
  .containerAvatarTool .tabLabel {
    font-size: .8em;
  }
}


@media only screen and (max-width: 800px) {
  .containerAvatarTool .tabLabel {
    font-size: .75em;
  }
}

@media only screen and (max-width: 770px) {
  .containerAvatarTool .tabLabel {
    font-size: 1em;
  }
}


@media only screen and (max-width: 600px) {
  .containerAvatarTool .tabLabel {
    font-size: .8em;
  }
}

.containerAvatarTool .tabLabel:hover {
  background: rgb(171, 195, 199);
  color: #000;
}

.containerAvatarTool .selected {
  z-index: 2;
}

.containerAvatarTool .selected .tabLabel {
  z-index: 3;
  background: #FAEAE7;
  color: #000;
  text-shadow: none;
  font-weight: 500;
}

.containerAvatarTool .categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
}

.containerAvatarTool .categoryBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  height: 100%;
}

.containerAvatarTool .catBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

@media only screen and (max-width: 500px) {
  .containerAvatarTool .catBtnContainer {
    width: 100%;
  }

  .containerAvatarTool .categories {
    display: block;
  }
}

.containerAvatarTool .catBtn {
  padding: 1vh 10px 1vh 10px;
  width: 65%;
}

.containerAvatarTool .back-to-main {
  position: absolute;
  top: 12px;
  padding: 0 1.7vw;
  color: #000;
  text-align: center;
  background: #EE8476;
  border-radius: 10px;
  margin-left: 10px;
}

.containerAvatarTool .menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerAvatarTool .menu-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

#tool-version {
  font-size: 14px;
  padding: 10px;
  bottom: 0;
  position: fixed;
}

.containerAvatarTool .book-title {
  font-weight: bold;
  color: blue;
}

.containerAvatarTool .book-title-error {
  font-size: 2rem;
  font-weight: bold;
  color: red;
}

.containerAvatarTool .saveBtnContainer {
  height: 10%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.containerAvatarTool .saveBtn {
  padding: 0 25px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .containerAvatarTool .box {
    margin: 1em 0;
  }

  .avatar-box {
    /*  height: 37vh; */
  }

  #config-box {
    padding: 0;
  }

  .containerAvatarTool .avatarContainer {
    height: 45%;
  }

  .containerAvatarTool .itemContainer {
    display: flex;
    align-items: flex-end;
    position: relative;
    bottom: 0;
    height: 55%;
  }

  .containerAvatarTool .button-types,
  .containerAvatarTool .button-canvas {
    font-size: calc(13px + 1vw);
  }

  .containerAvatarTool .button-canvas {
    margin-left: 10px;
  }

  .containerAvatarTool .type-section {
    height: 5vh;
    margin: 5px 0;
  }

  .containerAvatarTool .tab-section {
    height: 4vh;
  }

  .containerAvatarTool .view {
    overflow: auto;
    height: 35vh;
  }

  .containerAvatarTool .categoryBox {
    padding: 0 0 5px 0;
  }

  .containerAvatarTool .catBtnContainer {
    padding: .3em .5em 0 .5em;
  }

  .containerAvatarTool .catBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90%;
    padding: 5px;
    font-size: 4vw;
  }

  .containerAvatarTool .saveBtnContainer {
    margin-top: 5px;
  }

  .containerAvatarTool .itemPicker-header {
    position: relative;
    padding: 5px;
    margin-bottom: 5px;
  }

  .containerAvatarTool .itemPicker-header > h3 {
    margin: 0;
    font-size: calc(15px + 1vw);
  }

  .containerAvatarTool .back-to-main {
    font-size: calc(13px + 1vw);
  }

  #itemPicker-container {
    height: 28vh;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .containerAvatarTool .badge-light {
    display: none;
  }
}

.containerAvatarTool .AvatarView {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -webkit-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-out;
}

.containerAvatarTool .AvatarView_comp {
  position: relative;
  height: 100%;
  pointer-events: none;
}

.containerAvatarTool .AvatarView_img {
  display: block;
  height: 100%;
  pointer-events: none;
}

.containerAvatarTool .AvatarView_canvas {
  display: block;
  position: absolute;
}

.containerAvatarTool .loaderAvatarTool {
  background: rgba(225, 225, 225, .5);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.containerAvatarTool .loaderAvatarToolBox {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 250px;
  transform: translate(-50%, -50%);
}

.avatar-box {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.avatar-box img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.avatar-box img.avatarImgCustom {
  left: 50%;
  top: auto;
  bottom: 0;
  transform: translate(-50%, 0);
}

#avatar {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.catBtnContainer-forced .btn {
  position: relative;
}

.catBtnContainer-forced .btn .forced {
  position: absolute;
  top: -.5em;
  right: .5em;
  font-size: .8em;
  padding: 0 .6em;
  background: #ffb5a5;
  border-radius: 1em;
  color: #B43434;
}

.checkboxForce .custom-control-label {
  font-weight: bold;
  color: #ff6251;
}

.checkboxForce .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff6251;
  background-color: #ff6251;
}

.containerAvatarTool .item-picker-forced .active-item-picker-item {
  border-color: #ff6251;
}

.forced-removed-box {
  position: absolute;
  left: .5em;
  top: 3em;
  font-size: .6em;
  font-weight: bold;
  text-align: left;
  color: #B43434;
}

.forced-removed-box h5 {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  color: #B43434;
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 1;
}

.forced-removed-box p {
  margin: 0;
  padding: .3em 0;
  line-height: 1;
}

.forced-removed-box p:before {
  content: '-';
  display: inline-block;
  padding-right: .3em;
}

.custom-character-card {
  padding: 0;
  margin: 0;

  .custom-character-card-title {
    margin-bottom: 5px;
    color: $white;
    background-color: lighten($primary,5);
    border-radius: 5px;
    h4 {
      padding: 5px;
    }
  }
}

