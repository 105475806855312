@import "./src/config";

.previewDock {
  box-shadow: -10px 0 10px rgba($dark, .25);
  position: fixed;
  top: 0;
  bottom: 0;
  right: -50em;
  background: $white;
  padding: 10px;
  -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  z-index: 9999;
  width: 30em;
}

.main-panel.preview-panel .previewDock {
  right: 0;
}

.emulatorWindowDock {
  font-size: 10px;
  height: 92em;
  background: transparent url("../../../../assets/images/Pixel-2.svg") 50% 0 no-repeat;
  background-size: 49.4em auto;
}

.emulatorScreen {
  width: 37.7em;
  height: 74.2em;
  margin: 0 auto;
  position: relative;
  top: 6.4em;
  left: -1.1em;
  border-radius: 2em;
  overflow: hidden;
  background-color: $gray-500;
}

.emulatorScreen .header {
  background-color: $gray-900;
  height: 4em;
  line-height: 4em;
}

.emulatorScreen .headerText {
  color: $white;
  text-align: center;
  font-size: 2em;
  font-weight: 600;
  overflow: hidden;
  height: 2em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.emulatorScreen .header .btn {
  font-size: 1.75em;
  padding: 0 .75em;
  border-radius: .25em !important;
  text-transform: uppercase;
}

.emulatorScreen .navi {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  background: $gray-900;
}

.emulatorScreen {
  .screen {
    margin: 0 auto;
    width: 90%;
    position: relative;
    height: calc(100% - 14.5em);
    //z-index: 998
  }

  &.hideUi {
    .screen {
      height: calc(100% - 5.5em)
    }
  }
}


.emulatorScreen .scene {
  color: $gray-600;
  font-size: 1.6em;
  text-align: right;
  margin: 0 auto;
  line-height: 1.2em;
  padding: .5em 5%;
  background-color: $white;
  position: relative;
  z-index: 99;
}

.emulatorScreen .text {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: .5em;
  font-size: 2em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
}

.emulatorScreen .choice {
  padding: 2px;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
}

.emulatorScreen .choice .btn {
  font-size: 1.5em;
  border-radius: .7em;
  padding: 0;
  border-width: 1px;
  font-weight: 600;
}

.emulatorScreen .choice .btn.showAnswerAnalytics {
  width: calc(100% - 5em);
}

.emulatorScreen .choice .btn.haveAnswerImage {
  padding: 0 0 0 3em;
}

.emulatorScreen .choice_text .choice_name {
  display: none;
}

.emulatorScreen .reaction {
  display: none;
}

.emulatorScreen .reaction_text .reaction_name {
  display: none;
}

.emulatorScreen .dialogue_text {
  display: inline-block;
  border-radius: .5em;
  font-size: 1.75em;
  padding: .7em .8em;
  font-weight: 500;
  max-width: 100%;
  min-width: 50%;
  min-height: 64px;
  color: rgba($black, .75);
  position: relative;
  background-image: url(../../../../assets/images/talking-base.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
}

.emulatorScreen .thinking_name,
.emulatorScreen .dialogue_text .dialogue_name {
  position: relative;
  left: 0;
  display: block;
  font-size: .85em !important;
  height: 1px;
  top: 0;
  margin-top: -1.5em;
}

.emulatorScreen .thinking_name b,
.emulatorScreen .dialogue_text .dialogue_name b {
  color: $white !important;
  background: $primary_alt none;
  display: inline-block;
  border-radius: 1.5em;
  height: 2em;
  padding: 0 .75em;
  line-height: 2em;
  text-align: center;
  margin: 0 -.5em;
  min-width: 4em;
}

.emulatorScreen .dialogue_text:before {
  content: '';
  background: transparent no-repeat url(../../../../assets/images/talking-tail.svg);
  position: absolute;
  width: 2em;
  height: 3.2em;
  top: -.9em;
  left: 1em;
  transform: scale(-1.0, 1.3);
  background-size: contain;
  transform-origin: center bottom;
}

.emulatorScreen .dialogue_text.isPlayer {
  float: right;
}

.emulatorScreen .dialogue_text.isPlayer:before {
  transform: scale(1.0, 1.3);
  left: auto;
  right: 1em;
}

.emulatorScreen .result {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  background-color: rgba($white, .5);
}

.emulatorScreen .choice_text,
.emulatorScreen .thinking_text {
  border-radius: .5em;
  padding: .05em .6em;
  align-self: flex-end;
  font-size: 1.75em;
  animation: dialogue-talking-in 500ms cubic-bezier(0.5, 1.5, 0.125, 1);
  transform-origin: top right;
  background: $white;
  border-style: solid;
  border-image-source: url("../../../../assets/images/thinking-base.svg");
  border-image-slice: 10 16 10 16 fill;
  border-image-width: .5em 1em .5em 1em;
  border-image-outset: .5em;
  border-image-repeat: stretch stretch;
  display: inline-block;

  .EditBtn {
    right: -.35em;
    top: -1.1em
  }
}

.emulatorScreen .choice_text {
  display: block;
  margin-bottom: 1em;
}

.emulatorScreen .thinking_text.isPlayer {
  float: right;
}

.emulatorScreen .choice_text .textBox,
.emulatorScreen .dialogue_text .textBox,
.emulatorScreen .thinking_text .textBox {
  display: block;
  background-color: $white;
  border-radius: .5em;
  margin: .85em -.5em -.5em -.5em;
  padding: 1.25em .5em;
  font-family: Lato, sans-serif;
  font-weight: 500;
}

.emulatorScreen .choice_text .textBox {
  margin-top: -.5em;
  padding: .5em;
}

.emulatorScreen .dialogue,
.emulatorScreen .thinking,
.emulatorScreen .texting {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.emulatorScreen .dialogue:before,
.emulatorScreen .thinking:before,
.emulatorScreen .texting:before,
.emulatorScreen .dialogue:after,
.emulatorScreen .thinking:after,
.emulatorScreen .texting:after {
  content: '';
  clear: both;
  display: table;
}

.emulatorScreen .choice_name {
  font-size: 1.8em;
  font-weight: bold;
  padding: .3em;
  margin: .5em 0;
  width: 50%;
  color: $darkRed;
  border-radius: 0.8em;
  background: $secondary;
  background: linear-gradient(0deg, rgba($yellowDark, 1) 0%, rgba($secondary, 1) 100%);
  border: 2px solid $pinkLight;
  box-shadow: 0 2px 5px 0 rgba(52, 58, 64, 0.54);
}

.emulatorScreen .texting_name,
.emulatorScreen .thinking_name,
.emulatorScreen .thinking_name b,
.emulatorScreen .dialogue_name,
.emulatorScreen .dialogue_name b {
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
}

.emulatorScreen .texting_text {
  animation: dialogue-slide-in 500ms cubic-bezier(0.5, 0, 0.5, 1);
  padding: .8em;
  align-self: flex-end;
  font-size: 1.75em;
  background: transparent url("../../../../assets/images/texting-phone-bg.svg") no-repeat 50% 0;
  background-size: cover;
  height: 35vh;
  bottom: -3.5em;
}

.emulatorScreen .texting_text .texting_name {
  padding: 3em 1em .5em 1em;
  font-size: .91em;
}

.emulatorScreen .texting_text.isPlayer .texting_name {
  text-align: right;
}

.emulatorScreen .texting_text .texting_name b {
  color: $gray-900 !important;
  font-weight: 600 !important;
}

.emulatorScreen .texting_text .textBox {
  padding: .65em;
  font-size: .91em;
  margin: 0 2em;
  background: $white;
  border-radius: .75em .5em;
  position: relative;
  display: inline-block;
}

.emulatorScreen .texting_text.isPlayer .textBox {
  float: right;
  background: $primary_alt;
}

.emulatorScreen .texting_text .textBox:before {
  content: '';
  border: 1.3em solid transparent;
  border-top: .75em solid $white;
  border-right: .3em solid $white;
  position: absolute;
  left: -1em;
  top: 0;
}

.emulatorScreen .texting_text.isPlayer .textBox:before {
  left: auto;
  right: -1em;
  border: 1.3em solid transparent;
  border-left: 1.5em solid $primary_alt;
  border-right: 0 solid $primary_alt;
  border-top: .1em solid $primary_alt;
  z-index: 0;
}

.emulatorScreen .activeEdit {
  width: 100% !important;
}

.emulatorScreen .btn.passiveChoiceBtn {
  background-color: #ccc;
  border-color: #ccc;
}

.emulatorScreen .btn.answerType_romantic,
.emulatorScreen .btn.answerType_erotic {
  border-color: transparent !important;
  background: #F9807B;
  padding: .075em;
}

.emulatorScreen .btn.answerType_romantic span,
.emulatorScreen .btn.answerType_erotic span {
  box-shadow: none;
  background: #F9807B;
}

.emulatorScreen .btn .answerPrice {
  background: #484848;
  color: $white;
  position: absolute;
  right: 2px;
  top: 50%;
  width: 2.5em;
  height: calc(100% - 4px);
  transform: translate(0, -50%);
  border-bottom-right-radius: .6em;
  border-top-right-radius: .6em;
  font-style: normal;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;

  i {
    font-style: normal;
  }
}

.emulatorScreen .btn.answerType_romantic span:before,
.emulatorScreen .btn.answerType_erotic span:before {
  content: '';
  background: #F9807B url('../../../../assets/images/icon-romantic-choice.png') 50% 50%;
  background-size: cover;
  position: absolute;
  left: 2px;
  top: 50%;
  width: 2.5em;
  height: calc(100% - 4px);
  transform: translate(0, -50%);
  border-bottom-left-radius: .6em;
  border-top-left-radius: .6em;
}

.emulatorScreen .btn.answerType_paid,
.emulatorScreen .btn.answerType_romantic.btn-goldBorder,
.emulatorScreen .btn.answerType_erotic.btn-goldBorder {
  background: linear-gradient(#CB865B, #FFE412, #FFFFDB, #FFE412, #CB865B);
}

.emulatorScreen .btn.answerType_paid {
  border-color: transparent !important;
  background-color: #D99F69;
  padding: .075em;
}

.emulatorScreen .btn.answerType_romantic.btn-goldBorder span,
.emulatorScreen .btn.answerType_erotic.btn-goldBorder span {
  box-shadow: none;
  background: linear-gradient(#FAB4A8, #FDC8B0, #FFDEB7);

  span {
    background: transparent;
    box-shadow: none;
  }
}

.emulatorScreen .btn.answerType_paid.btn-goldBorder span {
  background: linear-gradient(#FAB5A8, #FDC9B0, #FFDEB7);
  box-shadow: none;

  span {
    background: transparent;
    box-shadow: none;
  }
}

.goldBorder {
  border: 3px solid $yellow;
}

.characterImage {
  width: 110%;
  height: calc(100% + 3em);
  position: absolute;
  left: -5%;
  bottom: -6.5em;
  background: transparent bottom left no-repeat;
  background-size: cover;
  transform: translate(-130%, 0);
  transition: all .5s ease-in-out;
}

.characterImage.active {
  transform: translate(-30%, 0);
}

.characterImage-user {
  width: 110%;
  height: calc(100% + 3em);
  position: absolute;
  left: 5%;
  bottom: -6.5em;
  background: transparent bottom right no-repeat;
  background-size: cover;
  transform: translate(130%, 0) scale(-1, 1);
}

.characterImage-user.active {
  transform: translate(20%, 0) scale(-1, 1);
  transition: all .5s ease-in-out;
}

.closeButton {
  border-width: 0.2rem;
  position: relative;
}

/**/
.preview-stepTextBox {
  position: relative;
  display: block;
  min-height: 2em;
  z-index: 99;
  word-break: break-word;
}

.preview-stepTextBox .EditBtn {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background: $white;
  padding: .4em;
  border-radius: .25em !important;
}

.preview-stepTextBox {
  .EditBtn {
    &.EditBtn-left {
      left: 0;
      right: auto;
      display: block;
    }

    &.EditBtn-left-bottom {
      left: 0;
      bottom: 0;
      top: auto;
      right: auto;
      display: block;
    }
  }
}

.preview-stepTextBox .choice-info-top {
  display: none;
}

.preview-stepTextBox.thinking_text .choice-info-top,
.preview-stepTextBox.choice_text .choice-info-top {
  display: block;
}

.choice-info-top .comments-button {
  position: absolute;
  bottom: 1em;
  left: -.55em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  padding: .25em 2em;
  background-color: $secondary;
  font-weight: normal;
  border-radius: 10px;
  pointer-events: all;
}

.choice-info-top {
  position: relative;
}

.preview-stepTextBox.thinking_text .choice-text-bubbles {
  position: absolute;
  right: 0;
  bottom: .45em;
}

.choice-text-bubbles {
  position: absolute;
  bottom: .6em;
  left: 1em;
  transform: scale(-1, 1);
}

.isPlayer .choice-text-bubbles {
  left: auto;
  right: 3em;
  transform: scale(1, 1);
}

.EditBtn img {
  width: 20px;
  height: 20px;
}

.preview-stepTextBox .EditBtn {
  display: block !important;
  font-size: 10px !important;
  padding: .25em !important;
  border-radius: .5em !important;
}

.preview-stepTextBox:hover .EditBtn:disabled,
.preview-stepTextBox.result .EditBtn,
.preview-stepTextBox.activeEdit .EditBtn {
  display: none;
}

.preview-stepTextBox.activeEdit .textBox {
  opacity: .4;
}

.preview-stepTextBox .EditBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba($white, .4);
  padding: .5em;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.preview-stepTextBox .EditBox {
  background: $white;
  font-size: .6rem;
}

.preview-stepTextBox .EditBox form,
.preview-stepTextBox .EditBox .input-group,
.preview-stepTextBox .EditBox textarea.form-control {
  width: 100%;
  height: 100%;
}

.preview-stepTextBox .EditBox .btn {
  position: relative;
  width: 35px;
  border-radius: 0 !important;
}

.preview-stepTextBox .EditBox .input-group-append {
  width: 71px;
  height: 30px;
  position: absolute;
  right: 0;
  top: -25px;
  background-color: $white;
  z-index: 99;
}

.preview-stepTextBox .EditBox img {
  width: 1.4em;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.preview-stepTextBox .EditBox .inpBox {
  position: relative;
  width: 100%;
  height: 100%;
}

.preview-stepTextBox .EditBox .char-limit-info-box {
  font-size: 10px;
  right: 71px;
  top: -24px;
  height: 28px;
  line-height: 28px;
}

.EditLoading {
  left: 0;
  top: 0;
  position: absolute;
  background: rgba($white, .4);
  width: 100%;
  height: 100%;
  z-index: 99;
  text-align: center;
  font-weight: normal;
}

.EditLoading i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.EditLoading .spinner-border {
  border-width: 2px;
}

.navi_current_step {
  position: absolute;
  left: 0;
  top: -1.35em;
  background: $gray-900;
  color: $white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: .25em;
  font-size: 1.1em;
  min-height: 1.5em;
  z-index: 99;
}

.emulatorScreen .navi .btn {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.previewChoicesModal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($dark, .4);
  font-size: 1.8em;
}

.previewPlayerModal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($dark, .4);
}

.btnPreviousChoices {
  border: 0 !important;
  line-height: 1;
  font-size: 1.5em !important;
  text-decoration: underline;
}

.avatarCanvas {
  width: auto;
  position: absolute;
  left: 0;
  height: 100%;
}

.dropoff {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 10em;
  height: 10em;
  border-radius: 50%;
  text-align: center;
  color: $dark;
  background-color: #fbb034;
  background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  border: 2px solid #fff;
  opacity: 1;
  -webkit-box-shadow: 0 2px 5px 0 rgba($dark, 0.54), inset -1px 4px 13px -4px rgba($dark, 0.54);
  -moz-box-shadow: 0 2px 5px 0 rgba($dark, 0.54), inset -1px 4px 13px -4px rgba($dark, 0.54);
  box-shadow: 0 2px 5px 0 rgba($dark, 0.54), inset -1px 4px 13px -4px rgba($dark, 0.54);
  font-weight: 600;
}

.dropoff.dropoffBad {
  background: linear-gradient(0deg, rgba($secondary, 1) 0, rgba($yellowDark, 1) 100%);
}

.dropoff .dropofftext {
  font-size: 1.2em;
  padding: 1.5em 0 0 0;
  line-height: 1;
  font-weight: 800;
}

.dropoff .dropoffvalue {
  line-height: 1em;
  font-size: 3.5em;
}

.dropoff .dropoffpercent {
  font-size: 2em;
}

.analytic_answer {
  text-align: center;
}

.analytic_answer_box {
  margin: 0 .05em;
  text-align: right;
  display: inline-block;
  background-size: 1.5em;
  background-repeat: no-repeat;
  background-position: center right;
  font-weight: bold;
  color: $white;
  background-color: $dark;
  border-radius: .5em;
  padding: 0 1.8em 0 0;
  height: 2em;
  width: 100%;
  line-height: 2em;
  border: 1px solid $gray-500;
  box-shadow: 0 2px 5px 0 rgba(52, 58, 64, 0.54), inset -1px 4px 13px -4px rgba(52, 58, 64, 0.54);
  font-size: 1em;
}

.analytic_answer_content,
.analytic_answer_content_not_img {
  display: flex;
  align-items: center;
  padding: .4em .75em .8em 3.75em;
  line-height: 1.2em;
  background: $dark;
  text-align: left;
  color: $white;
  min-height: 4em;
  border-radius: .75em;
  box-shadow: inset -1px 4px 13px -4px rgba($black, 1);
}

.analytic_answer_content_not_img {
  padding: .4em .75em;
}

.editBtnLeftPos {
  .analytic_answer_content_not_img {
    padding: .4em 3em;
  }
}

.btn-goldBorder .analytic_answer_content_not_img,
.btn-goldBorder .analytic_answer_content {
  background-color: $yellow;
  color: $dark;
}

.analytic_answer_content.haveAnswerImage {
  padding: .4em .75em .8em .75em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.answerPlayImageBox {
  position: absolute;
  left: 0;
  top: 50%;
  width: 3em;
  height: 4.2em;
  overflow: hidden;
  border-top-left-radius: .75em;
  border-bottom-left-radius: .75em;
  transform: translate(0, -50%);
}

.answerPlayImageBox img {
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
}

.answerPlayImageBox img.loyalty {
  height: 200%;
  left: auto;
  right: -20%;
  top: 0;
}

.answerPlayImageBox img.creativity {
  height: 180%;
  left: -30%;
  top: 0;
}

.answerPlayImageBox img.ambition {
  transform: translate(-50%, -50%);
}

.answerPlayImageBox img.curiosity {
  height: 150%;
  transform: translate(-50%, -40%);
}

.answerPlayImageBox img.humor {
  height: 180%;
  transform: translate(-42%, -25%);
}

.answerPlayImageBox img.independence {
  height: 300%;
  transform: translate(-61%, -46%);
}

.answerPlayImageBox img.kindness {
  height: 250%;
  transform: translate(-32%, -30%);
}

.analyticAnswerInfoBoxs {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1em;
  text-align: right;
  width: 5em;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: .25em 0;
  flex-direction: column;
  height: 100%;
}

.prev-addStepLabel {
  font-size: 1.4em;
  font-weight: bold;
}

.PreviewEditScene-content .inpBoxEdit {
  display: block;
  position: relative;
}

.prevCoverBox {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: $gray-500 center no-repeat;
  background-size: cover;
  z-index: 9999;
  text-shadow: 0 0 10px rgba($black, 1);
}

.prevCoverBox h1 {
  color: $white;
  font-size: 3.6em;
}

.prevCoverBox h2 {
  color: $white;
  font-size: 2em;
}

.prevCoverBox p {
  color: $white;
  max-height: 30vh;
  overflow: hidden;
  padding: .5em;
}

.prevCoverBox-content {
  position: absolute;
  width: 80%;
  left: 50%;
  bottom: 2em;
  transform: translate(-50%, 0);
}

.prevCoverBox-avatar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  padding-top: 190%;
}

.prevCoverBox-avatar .avatarCanvas {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 130%;
  transform: translate(-50%, 0);
}

.prevCoverLogoBox {
  color: $white;
  text-shadow: none;
  text-align: center;
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translate(-50%, 0);
  height: 4em;
  line-height: 4em;
  opacity: .5;
  padding-left: 2.2em;
}

.prevCoverLogoBox svg {
  width: 4em;
  height: 4em;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, 0);
}

.prevCoverLogoBox b {
  text-shadow: none;
  display: inline-block;
  font-size: 2em;
  height: 4em;
  line-height: 4em;
}

.prevLogoBox {
  color: $white;
  text-shadow: none;
  text-align: center;
  position: absolute;
  top: 5.75em;
  right: 7em;
  height: 2em;
  line-height: 2em;
  opacity: .5;
  padding-left: 2.2em;
}

.prevLogoBox svg {
  width: 2em;
  height: 2em;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.prevLogoBox b {
  text-shadow: none;
  display: inline-block;
  font-size: 1em;
  height: 2em;
  line-height: 2em;
}

.analyticsPanelInPreview {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  border-radius: 5px;
}

.analyticsPanelInPreviewTitle {
  padding-right: 10px;
}
