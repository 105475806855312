.PageNotFoundH1 {
    position: fixed;
    left: 50%;
    top: 50%;
    color: #8e908c;
    font-size: 50vw;
    transform: translate(-50%, -50%);
    opacity: .3;
}

.PageNotFoundH2 {
    position: fixed;
    left: 50%;
    top: 50%;
    color: #8e908c;
    font-size: 5vw;
    transform: translate(-50%, -50%);
}