@import "../../../config";

.InputWritingBox {
  background-color: $white;

  .input-group {
    border-top: 1px $secondary solid;
  }

  .input-group-append .btn {
    border-radius: 0 !important;
  }

  .tagItem {
    border-radius: 0;
    padding: .1em 2.5em .1em 1em;
  }

  .tagItem .close {
    border-radius: 0;
    width: 1em;
    height: 100%;
  }
}
