@import "src/config";

.textBodyConfirmModal {
  text-align: center;
}

.textVariableBodyConfirmModal {
  margin: 10px 0;
  font-weight: bold;
}
