@import "../../../config.scss";

.card-img-left {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.CardColumns-avatar-prop .row {
  margin: 0;
}

.CardColumns-avatar-prop .col {
  padding: 0;
}

.CardColumns-avatar-prop .card-body {
  height: 100%;
  padding: 0 0 3.5em 0;
  position: relative;
}

.CardColumns-avatar-prop .card-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.CardColumns-avatar-prop .card-title,
.CardColumns-avatar-prop .card-text {
  padding: .5em;
  margin: 0;
}

.imageSpinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.imageClickToEdit {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: .25em;
  font-size: .8em;
  font-weight: bold;
  text-align: center;
  color: $primary;
  background-color: rgba($white, .6);
  z-index: 9;
}

.imageNotValidate {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: .25em;
  font-size: .8em;
  font-weight: bold;
  text-align: center;
  color: $primary;
  background-color: rgba($white, .6);
  z-index: 9;
}

.ValuesForm-img-preview {
  position: relative;
  height: 0;
  padding-top: 50%;
  margin: 0 auto;
  width: 60%;
  z-index: 1;
}

.ValuesForm-img-preview img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  z-index: 1;
}

.ValuesForm-img-preview-wrap {
  position: relative;
}

.ValuesForm-img-preview-wrap input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
}
