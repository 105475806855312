@import "../../../../../../config";

.listItem {
  margin-top: 0.25rem;
  padding: 0.25rem;

  &:nth-child(odd) {
    background: $gray-200;
  }

  /* scrollbar width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track scrollbar */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle scrollbar */
  ::-webkit-scrollbar-thumb {
    background: $secondary;
  }

  /* Handle on hover scrollbar */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}


.column {
  display: flex;
}

.label {
  white-space:nowrap;
  font-weight: bold;
}

.text {
  overflow: auto;
  white-space:nowrap;
  border-bottom: 1px dashed $gray-800;
  margin-left: 0.5rem;
  padding: 0 0.25rem;
  width: 100%;
}

.status {

}

.characterNameText{
  font-weight: bold;
  color: $primary;
}

.expressionNameText {
  font-weight: bold;
  color: $primary;
  text-transform: capitalize;
}

.action {
  justify-content: end;
}

/* STATUSES */

.uploadStatusReady {
  color: $blue;
  font-weight: bold;
}

.uploadStatusUploading {
  color: $blue;
  font-weight: bold;
  animation: blinker 1.5s ease-out infinite;

  @keyframes blinker {
      50% {
      opacity: 0;
      }
  }
}

.uploadStatusUploaded {
  color: $gray-900;
  background: $success;
  font-weight: bold;
}

.uploadStatusError {
  color: $gray-100;
  background: $danger;
}

