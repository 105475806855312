@import "src/config";
.dropZone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
  text-align: center;

  font-weight: bold;
  border: 2px dashed lightgray;
  background: $gray-200;
}
