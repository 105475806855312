.earnings-table-container {
  width: 100%;
}

.earnings-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.earnings-table thead th {
  padding: 8px;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
}

.earnings-scrollable-tbody {
  max-height: 200px;
  overflow-y: auto;
}

.earnings-table tbody td {
  padding: 8px;
  border: 1px solid #ddd;
  white-space: nowrap;
}

.earnings-info-section {
  margin-top: 20px;
  font-size: 13px;
}

.earnings-info-section p {
  margin-bottom: 10px;
}

.earnings-info-section ul {
  list-style-type: none;
  padding-left: 0;
}

.earnings-info-section li {
  margin-bottom: 10px;
}

.earnings-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.earnings-backdrop {
  opacity: 0 !important;
}