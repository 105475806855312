@import "src/config";

.container {
  display: block;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  text-shadow: 3px 3px 4px black;
}

.imageContainer {
  max-height: 22vh;
  max-width: 15vh;
  color: gray;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 0px 15px 0px black;
    border-radius: 0.75rem;
}

.titleContainer {
  font-size: 2.6vh;
  margin-top: 2vh;
}

.authorsContainer {
  margin-top: 2vh;
  font-size: 1.9vh;
}

.authorContainer {
  height: 6vh;
  display: flex;
  align-items: center;
}

.authorIconContainer {
    width: 5vh;
    height: 5vh;
  margin-right: 1vh;
}

.authorIcon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #8084b4;
    object-fit: cover;
}

.descriptionContainer {
  margin-top: 2vh;
  font-size: 1.7vh;
  line-height: 2vh;
}
