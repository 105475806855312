@import "../../../config";

.CharactersCatListTitle {
  position: relative;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
  padding: .7em .5em .75em 2em;
  cursor: pointer;

  .icon {
    position: absolute;
    line-height: 1;
    border: 0;
    width: .9em;
    height: .9em;
    border-radius: 2em;
    left: .45em;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin: 0;
    padding: 0;
    background-color: $primary;
    outline: none !important;
  }

  img {
    width: .45em;
    position: absolute;
    left: .45em;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }


}
