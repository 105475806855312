@import "../../../../config";

.title {
  text-align: center;
  color: $white;
  background-color: $primary;
  padding: 6px 0 0 0;
  margin: 0 0 20px 0;
  border-radius: 5px;
}
.listGroup {
  display: flex;
  justify-content: center;
  min-height: 350px;
}


