@import "../../../config.scss";

.articles-box {
  h3 {
    padding-bottom: .5em;

    a {
      color: $dark !important;
    }
  }

  .text-content {
    padding-bottom: 1em;
    font-size: .75em;
  }
}
