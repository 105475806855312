@import "src/config";

.formAlert {
  margin: 0 0 1rem 0;
  width: 100%;
}

.formTitle {
  text-align: center;
}

.formMessage {
  display: flex;
  justify-content: center;
  text-align: center;
}

.formSubText {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.8rem;
  
  button {
    color: $theme-primary-btn-background;
    &:hover {
      text-decoration: underline;
    }
  }
}

.orSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  
  svg {
    margin: 0 1rem;
  }
}