.CharPreviewImg {
    cursor: pointer;
    position: relative;
    min-width: 30px;
    min-height: 30px;
}

.CharPreviewImg img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.CharPreviewImgModal {
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 9999;
}

.CharPreviewImgModal img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 75vw;
    max-height: 75vh;
    object-fit: contain;
}

.CharPreviewImgSpinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.CharPreviewImg,
.CharPreviewImgModal i {
    background: transparent center no-repeat;
    background-size: contain;
}

.CharPreviewImgModalHover {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}

.CharPreviewImgModalHover img {
    object-fit: contain;
    box-shadow: 0 0 10px #000000;
    background: #FFFFFF;
}

.CharPreviewImg .card-text {
    max-width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.CharPreviewImgTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 99;
    text-align: center;
    font-size: 1.2em;
    width: 100%;
}

.CharPreviewImgTitle span {
    padding: .3em;
    background: rgba(255, 255, 255, .8);
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
