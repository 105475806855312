@import "./src/config";

.pageAddBook {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 35rem;
  text-align: center;

  .btn {
    margin: .5em;
  }

  .IconError {
    width: 3rem;
    height: 3rem;
    margin: 1em;
  }

  .text-info {
    color: #287965 !important;
  }
}
