@import "../../../../config";

.privacy-policy-page {
  margin: 0;
  padding: 1em 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background: rgba($primary_alt, 1) top center no-repeat;
  background: -moz-linear-gradient(top, rgba($gradient_1, 1) 0%, rgba($gradient_2, 1) 45%, rgba($gradient_3, 1) 100%);
  background: -webkit-linear-gradient(top, rgba($gradient_1, 1) 0%, rgba($gradient_2, 1) 45%, rgba($gradient_3, 1) 100%);
  background: linear-gradient(to bottom, rgba($gradient_1, 1) 0%, rgba($gradient_2, 1) 45%, rgba($gradient_3, 1) 100%);
  font-family: 'Montserrat', sans-serif;
}

.privacy-policy-page .container {
  position: relative;
}

.privacy-policy-page .logo {
  color: $white;
  width: 4em;
  height: 4em;
  position: absolute;
  left: -4em;
  top: 1em;
}

.privacy-policy-page-title {
  color: $white;
  font-weight: 800;
  border-bottom: 1px solid $white;
  margin-bottom: .6em;
  padding-top: .5em;
  padding-bottom: .25em !important;
  font-size: 2.9em;
  text-align: center;
}
