@import "../../../config";

.NewsItem {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.12), 0 4px 16px rgba(52, 58, 64, 0.25);
  border-radius: 1em;
}

.NewsItem-Container {
  max-width: 1024px;
  min-width: 560px;
  padding-bottom: 2em;
}

.Recent-Container {
  max-width: 1024px;
  min-width: 560px;
  padding-bottom: 1em;
}

@media (max-width: 780px) {
  .NewsItem-main {
    overflow: hidden;
    overflow-x: auto;
  }

  .NewsItem-Container {
    max-width: 100%;
    min-width: auto;
  }
}

.NewsItem-Container-title {
  margin: 0;
  padding: .5em;
}

.NewsItem {
  .card-body {
    padding: 1em;
    box-shadow: none;
  }
}

.card-img-left {
}

.card-horizontal {
  height: 100%;
  display: flex !important;
  flex: 1 1 auto;
  box-sizing: border-box;
}

@media (max-width: 780px) {
  .card-horizontal {
    display: block !important;
  }
}

.img-square-wrapper {
  display: block;
  width: 30%;
  max-width: 30em;
  background: $gray-200;
  border-radius: 1em;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background-color: rgba($black, .2);
    cursor: pointer;
  }

  &:hover:before {
    background-color: rgba($black, .4);
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3.3em;
    height: 3.3em;
    border: 2px solid $white;
    background-color: rgba($white, .2);
    border-radius: 2.5em;
    z-index: 91;
    cursor: pointer;
  }

  &.link:before,
  &.link:after {
    display: none;
  }
}


@media (max-width: 780px) {
  .img-square-wrapper {
    width: 100%;
    max-width: 100%;
    height: 0;
    padding-top: 100%;
  }
}

.NewsItemBody {
  width: 70%;
  height: 13.5em;
}

@media (max-width: 780px) {
  .NewsItemBody {
    width: 100%;
  }
}

.img-square-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 780px) {
  .img-square-wrapper img {
    position: absolute;
    left: 0;
    top: 0
  }
}

.CardAvatar-box {
  position: absolute;
  margin: 0;
  padding: 1em 1em 1em 0;
  color: $gray-500;
  font-size: .9em;
  bottom: 0;


  .CardAvatar {
    display: inline-block;
    position: relative;
    padding: .25em .25em .25em 2.4em;
    margin: .1em;
  }

  .avatar {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    border: 2px solid $gray-600;
  }
}

@media (max-width: 780px) {
  .CardAvatar-box {
    position: relative;
    /* display: none; */
  }
}


.NewsItemContent {
  font-size: .9em;
  line-height: 1.2em;
  margin: 1em 0 2.5em 0;
  padding: 0;
  height: 3.6em;
  overflow: hidden;
}

@media (max-width: 780px) {
  .NewsItemContent {
    position: relative;
    height: auto;
    max-height: 3.5em;
    margin: 0 0 .5em 0;
    /* display: none; */
  }
}

.NewsItem-long .NewsItemContent {
  height: 8.5em;
}

.NewsActionIcon {
  width: .8em !important;
  height: .8em !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 95;
  cursor: pointer;
}

.img-square-wrapper .avatarCanvas {
  width: auto;
  height: 90%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}

.NewsItemTitle {
  max-height: 2.5em;
  position: relative;
  margin: 0;
  padding: 0 1.5em 0 0;

  h5 {
    max-height: 2.5em;
    font-size: .85em;
    overflow: hidden;
  }

  @media (max-width: 780px) {
    max-height: none;
  }
}

.NewsItemAuthor {
  height: 1.3em;
  position: relative;
  margin: 0;
  padding: 0 1.5em 0 0;
  overflow: hidden;

  h5 {
    font-size: .65em;
  }

  @media (max-width: 780px) {
    max-height: none;
  }
}

.NewsItemAuthor {
    height: 1.3em;
    position: relative;
    margin: 0;
    padding: 0 1.5em 0 0;
    overflow: hidden;
}

@media (max-width: 780px) {
    .NewsItemAuthor {
        max-height: none;
    }
}

.newsItemMenu {
  position: absolute;
  right: 0;
  top: 0;

  .newsItemMenu-btn {
    border-radius: 2em;
    font-weight: 900;
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 1;
    width: 1.25em;
    height: 1.25em;

    &:after {
      display: none;
    }
  }
}

.recentEpisodeLink {
  position: relative;
  padding-left: 2em;

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 1.5em;
  }
}
