@import "src/config";

.imgWarning {
  width: 50px;
  overflow: hidden;
}

.warningText {
  margin-left: 15px;
  a {
    color: black;
  }
  a:hover {
    color: $primary;
  }
}

.revenueShare {
  margin: 20px auto;
  padding: 0 30px;
  text-align: center;
  background-color: $gray-100;
  border-radius: 10px;
  border: 1px solid $gray-300;
}

.revenueShareLabel {
  margin: 0;
  padding-top: 5px;
  text-align: center;
}

.revenueAnnounce {
  margin-top: 25px;
  padding-bottom: 0;
  font-size: 13px;
}
