@import '../../../../config.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0;
    padding: 5px;
    background-color: lighten($secondary,14);
    width: 100%;
    border-bottom: 1px solid $secondary;
    border-top: 1px solid $secondary;
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.avatarCanvas {
  width: 100px;
  height: 100px;
  border: 1px solid $secondary;
  border-radius: 10px;
  background-color: lighten($secondary,16);
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  margin: 5px;
  border-radius: 10px;
  min-width: 80px;
}

.menuButton {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;

  &:after {
    display: none;
  }
}
