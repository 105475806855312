@import '../../../../config.scss';

.modal {
  background: rgba(0, 0, 0, 0.7);
}

.modalTitle {
  text-align: center;
  margin: 10px 15px;
  background-color: $primary;
  color: $white;
  border-radius: 5px;
}

.modalBody {
  min-height: 300px;
}

.modalButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button {
  min-width: 150px;
  margin: 15px 20px 0 20px;
  border-radius: 10px;
}

.buttonExternal {
  min-width: 100px;
}

.spinner {
  position: absolute;
  top: 0;
  right: 10px;
}
