@import '../../../../config.scss';

.cropper-area-picker {
    height: 100%;
    width: 421px;

    .cropper-modal {
        background-color: lighten($secondary, 15) !important;
        border-radius: 10px;
        border: 1px solid $primary;
    }
    .cropper-point {
        height: 7px !important;
        width: 7px !important;
    }
}
