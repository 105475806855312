.searchContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
}

.button {
  min-width: 90px;
  max-height: 31px;
  overflow: hidden;
}
