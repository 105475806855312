.container {
  margin-bottom: 1rem;
}

.title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.value {
  padding-left: 0.25em;
}
