.ScenePreviewImg {
    height: 70px;
    width: 70px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 999;
}

.ScenePreviewImgModal {
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 9999;
}

.ScenePreviewImgModal i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    height: 75vh;
}

.ScenePreviewImg,
.ScenePreviewImgModal i {
    background: transparent center no-repeat;
    background-size: contain;
}


