@import '../../../../config.scss';

.container {
  background-color: $gray-100;
  padding: 10px;
  align-items: center;
  width: 100%;
  min-height: 375px;
  max-height: 550px;
  overflow: auto;
}

.addExpressionButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  min-width: 300px;
  min-height: 50px;
}
