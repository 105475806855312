@import "../../../config";

.WizardWrapper, .WizardWrapperW {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba($body-bg, .2);
}

.WizardWrapperW {
  background: rgba($body-bg, .2); /* transparent; */
}

.WizardWelcomeBox {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40em;
  max-width: 80%;
  background: $body-bg;
  padding: 2em;
  border-radius: .5em;
  border: 2px solid $primary;
  text-align: center;
}

.books-list .wizardActive,
.wizardBtnBox,
.sidebarItem {
    position: relative;
}

.wizardBtnBox {
    display: inline-block;
}

@keyframes wizardActiveBtn {
    from {
        box-shadow: 0 0 .5em 0 rgba(255, 0, 0, 1);
    }
    to {
        box-shadow: 0 0 .5em 0 rgba(255, 0, 0, 0);
    }
}

.books-list .wizardActive a,
.wizardActive .books-list-titleBox a,
.wizardActive .form-control,
.wizardActive .btn {
    animation: wizardActiveBtn 500ms infinite alternate;
}

@keyframes sidebarArr {
    from {
        transform: translate(-50%, -15%);
    }
    to {
        transform: translate(-50%, -25%);
    }
}

@keyframes sidebarArr_1 {
    from {
        transform: translate(-50%, 0);
    }
    to {
        transform: translate(-50%, -10%);
    }
}

.sidebarArr {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -15%);
    z-index: 999;
    text-align: center;
    animation: sidebarArr 700ms infinite alternate;
}

.books-list .sidebarArr {
    transform: translate(-50%, 0);
    animation: sidebarArr_1 700ms infinite alternate;
}

.sidebarArr img {
    width: 1em;
}

.sidebarArr span {
  color: red;
  font-weight: bold;
  text-align: center;
  display: block;
  line-height: 1;
  text-shadow: 2px 0 10px rgba($dark, 1);
  width: 10em;
}

@keyframes skipWizardBox {
    from {
        background: rgba(0, 0, 0, .3);
    }
    to {
        background: rgba(0, 0, 0, .1);
    }
}

.skipWizardBox {
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: .5em;
  z-index: 99999;
  border-radius: .4em;
  background: rgba($body-bg, .3);
  animation: skipWizardBox 500ms infinite alternate;
}
