.memoryBankModalDialog {
    height: 90vh;
    max-width: 90vw;
}

.memoryBankModalBody {
    height: 75vh;
}

.memoryBankAddSlotButton {
    margin-left: 1rem;
}

.lockedTooltip {
    position: absolute;
    top: 0.5rem;
    right: 0;
}
