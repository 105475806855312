@import "../../../../config";

.heartsValue {
  min-width: 50px;
}

.heartsTitle, .usersTitle, .stepTitle {
  color: $darkRed;
}

.usersTitle, .stepTitle {
  margin-left: 5px;
}
