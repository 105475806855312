@import "config";

.row {
  width: 100%;
  border: 1px solid #e0e0e0;
  margin: 0 0 1rem 0;
  border-right: 5px solid transparent;
  &:hover {
    background-color: #f8f8f8;
  }
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.changed {
   border-right: 5px solid $primary
}

.icon {
  width: 8rem;
  height: 8rem;
  border: 1px solid $light;
  border-radius: 1rem;
  box-shadow: 0 0 8px 2px $light;
  object-fit: cover;
}

.memoryNameRow {
  height: 30%;
  padding: 0.25rem 0.5rem;
}

.memoryName {
 padding: 0 1rem;
}

.infoContainer {
  height: 70%;
  border-radius: 8px;
  padding: 0 0.5rem;
}

.infoTitleRow {
  height: 35%;
  min-height: 2rem;
  // Temporary hide. Waiting for the design
  //background: $gray-200;
  padding-left: 0.5rem;
  font-size: 0.8rem;
}

.infoValuesRow {
  height: 65%;
  // Temporary hide. Waiting for the design
  //background: $gray-200;
  font-size: 0.8rem;
}

.infoValuesCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0.25rem 0.25rem 0.5rem;
}

.infoLabel {
  font-size: 0.7rem;
  font-weight: 500;
}

.infoValue {
  font-size: 1rem;
  font-weight: 600;
}

.whiteBorder {
  // Temporary hide. Waiting for the design
  //border: 2px solid $white;
  border-radius: 8px;
}
