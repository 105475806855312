.imgCoverBook {
    width: auto;
    max-height: 40vh;
    margin-bottom: 1.5em;
}

.cropContainer {
    position: relative;
    width: 100%;
    height: 512px;
}