@import "src/config";

.imageAvatarWrapper {
  padding: 0;
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid $pink;
  overflow: hidden;
}

.rounded {
  border-radius: 50%;
}

.buttons {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%,0);
  width: 100%;
  opacity: 0.8;
 }

.imageButton:focus {
  background: $secondary;
  outline: none !important;
  box-shadow: none !important;
}

.imageButton:hover {
  background: lighten($primary,10)
}

.imageButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 30px;
  background: $secondary;
  border: 1px solid $primary;
}

.imgAvatar {
  height: 100%;
  object-fit: contain;
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
  transform: translate(-50%,-50%);
}

.spinner {
  position: relative;
  top: 45%;
}
