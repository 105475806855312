@import "../../../config";
@import "./range-slider";

.list-column-header {
  position: relative;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  padding: .5em .5em .5em 0;
}

.list-column-header-toggle {
  position: absolute;
  line-height: 1;
  border: 0;
  width: .9em;
  height: .9em;
  border-radius: 2em;
  left: -1.35em;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0;
  padding: 0;
  background-color: $gray-200;
  outline: none !important;
}

.list-column-header-toggle-img {
  width: .45em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  transition: all .5s ease-in-out;
}

.list-column-header-toggle-img.inactive {
  transform: translate(-50%, -50%) rotate(0);
}

.books-list-item {
  border-bottom: 1px solid $light;
  margin: 0 0 0 2em;
  padding: .1em 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.books-list-item .col {
  position: relative;
}

.books-list-item .indicator-disabled,
.books-list-item .indicator {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: .4em;
  background-color: rgba($gray-700, .4);
}

.books-list-item .indicator-disabled {
  background-color: rgba($gray-700, .4) !important;
  opacity: .3;
}

.books-list-item .colNum {
  position: absolute;
  left: -2em;
  width: 2em;
  top: 0;
  padding: .4em .1em 0 0;
  text-align: right;
  font-size: .9em;
  white-space: nowrap;
}

.sortItem.books-list-item .colNum {
  display: none;
}

.books-list-box {
  position: relative;
  padding: .5em 0 1em 0;
}

.books-list-box .boxSpinner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  background: rgba($body-bg, .4);
}

.hoverGroup {
  margin: .1em 0 .1em 2em;
  height: 0;
  background: $gray-200;
  border-bottom: 1px solid $light;
  transition: all .35s ease-in-out;
  border-left: .4em solid $light;
}

.books-list-box:hover .hoverGroup.d-block {
  height: 2.5em;
}

.books-list-link {
  font-weight: normal;
  font-size: 1em;
  color: $dark;
}

.books-list-link:hover {
  color: $secondary;
  text-decoration: underline;
}

.books-list-titleBox {
  padding: .25em 2.5em .25em .5em;
  display: inline-block;
  position: relative;
  width: 100%;
  white-space: pre-wrap;
}

.books-list-locked {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1em;
}

.books-editNameBtn {
  position: absolute;
  width: 2em;
  height: 2em;
  top: .3em;
  right: 0;
  display: none;
}

.books-list-titleBox:hover .books-editNameBtn {
  display: block;
}

.books-editNameBtn img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1em;
}

.books-list-additionalBox {
  max-width: 20em;
  background: rgba($light, .2);
  border-left: 1px solid $light;
  font-size: .9em;
}

.books-list-additionalBox.long {
  max-width: 40em;
}

.books-list-header .books-list-additionalBox {
  background: transparent;
  border: 0;
}

.books-list-actionBox {
  max-width: 12em;
  border-left: 1px solid $light;
}

.books-list-actionBox:last-child {
  border: 0;
}

.books-list-header .books-list-actionBox {
  border: 0;
}

.books-list-actionBox .btnImg {
  width: 1.8em;
}

.books-list-actionBox .btn {
  padding: 0;
  width: 2.5em;
  height: 2.5em;
  line-height: 1;
}

.books-list-actionBox .btn.btn-block {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0 .5em;
  font-size: 1em;
  box-shadow: none !important;
  border: 0 !important;
}

.books-list-actionBox .dropdown-menu {
  z-index: 99999;
}

.books-list-additionalBox-title {
  font-weight: normal;
  font-size: .9em;
  margin: 0;
  padding: 0;
  line-height: 1;
  height: 100%;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  &.ordering {
    cursor: pointer;

    &:before {
      content: "\2191";
      opacity: .4;
      position: absolute;
      left: .65em;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:after {
      content: "\2193";
      opacity: .4;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
    }
  }

  &.ordering-false:before {
    opacity: 1;
  }

  &.ordering-true:after {
    opacity: 1;
  }

  span {
    position: relative;
    padding: .5em 0;
    vertical-align: bottom;
    display: block;
    width: 100%;
    box-sizing: border-box;

    .custom-control-label {
      font-size: .85em;
      line-height: 1.25em;
    }
  }

  &.ordering span {
    padding: .5em 0 .5em 1em;
  }
}

.books-list-additionalBox-title .custom-control-label:before,
.books-list-additionalBox-title .custom-control-label:after {
  top: 0 !important;
}

.BookTitleForm {
  padding-top: .1em;
}

.BookTitleForm input[type="text"] {
  border-style: dashed;
}

.BookTitleForm .btn {
  position: relative;
  width: 35px;
  border-color: $light;
}

.BookTitleForm .btn img {
  width: 1.4em;
  position: absolute;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.BookAuthorsList {
}

.BookAuthorsList button,
.BookAuthorsList span {
  display: inline-block;
  padding: .2em;
  font-size: .9em;
}

.books-list-additionalBox button:after,
.books-list-additionalBox span:after,
.BookAuthorsList button:after,
.BookAuthorsList span:after {
  content: ',';
}

.books-list-additionalBox button:last-child:after,
.books-list-additionalBox span:last-child:after,
.BookAuthorsList button:last-child:after,
.BookAuthorsList span:last-child:after {
  display: none;
}

.books-list.books-list-active .books-list-item {
  visibility: visible !important;
  transform: translate3d(0, 0, 0) !important;
}

.checkboxStoryTemplate {
  position: relative;
  padding: .5em;
  border: 0;
  cursor: pointer;

  &.disabled {
    opacity: .3;
  }

  label {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1em;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-title {
    font-size: .85em;
    margin: 0;
    padding: .75em .25em .25em .25em;
    font-weight: bold;
  }

  .card-body {
    margin: 0;
    padding: 100% 0 0 0;
    width: 100%;
    background: $gray-500;
    border-radius: .25em;
    border: .25em solid transparent;
    position: relative;
  }

}

.checkboxStoryTemplate.checked .card-body {
  border-color: $primary_alt;
}

.checkboxStoryTemplate .form-check,
.checkboxStoryTemplate input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.licensed-spinoff {
  font-weight:bold;
}
