@import "src/config";

.formAlert {
  margin: 0 0 1rem 0;
  width: 100%;
}

.formTitle {
  text-align: center;
}

.formSubText {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.8rem;
  
  a {
    color: $theme-primary-btn-background;
  }
}

.orSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  
  svg {
    margin: 0 1rem;
  }
}

.externalButtons {
  margin: 8px 0;
}

.externalButtonsContainer {
  margin: auto;
  width: 75%;
}

@media (max-width: 575.98px) {
  .externalButtonsContainer {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .externalButtonsContainer {
    width: 90%;
  }
}