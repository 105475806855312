@import "src/config";

.formTitle {
  text-align: center;
  font-weight: bold;
}

.formText {
  text-align: center;
  padding: 2rem 0;
}
