@import "src/config";

.header {
  background: $gray-100;
  min-height: 25px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: center;
  border: 1px solid $gray-300;
}


