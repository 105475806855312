@import '../../../../config.scss';

.modal {
  background: rgba(0, 0, 0, 0.7);
}

.modalButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lighten($secondary,13);
  border-radius: 10px;
  margin: 15px 0 10px 0;
}

.button {
  margin: 10px 20px;
  min-width: 120px;
}

.buttonExternal {
  min-width: 100px;
  min-height: 40px;
}
