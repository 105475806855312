.listChaptersBox {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    overflow-y: auto;
}

.listChaptersBox-page {
    height: 40vh;
}

.imgAvatar {
    width: auto;
    max-height: 40vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.imgAvatar-col {
    overflow: hidden;
}

.textareaAuthor, .textareaAuthor.form-control:valid {
    width: 100%;
    max-height: 30vh;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #dee2e6;
    text-align: left;
    resize: none;
    background-image: none;
    word-break: normal;
}

.inputAuthor, .inputAuthor.form-control:valid {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #dee2e6;
    text-align: center;
    background-image: none;
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: .5em;
    color: #000000 !important;
}

.uploadFormBox {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(128, 128, 128, .6);
    width: 100%;
    padding: 1em 2em;
    border-top: 1px solid #dee2e6;
}

.imgAvatar-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 22em;
}

.UserMailIcon {
    display: inline;
    width: 1em;
    margin: 0 .5em;
}
