
.modalCharacterDialog {
  max-width: 90%;
  height: 80%;
}

.modalBackgroundDialog {
  max-width: 70%;
  height: 80%;
}

.modalCharacterDialog, .modalBackgroundDialog {
  @media (max-width: 768px) {
    max-width: 95%;
  }

  @media (max-height: 768px) {
    height: 90%;
  }
}


.modalContent {
  height: 80%;
  margin: auto;
}

.modalHeader {
  display: block;
}

.modalTitle {
  text-align: center;
  display: flex;
  width: 100%;
}

.modalBody {
  display: flex;
  flex-direction: column;
}
