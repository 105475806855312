@import "src/config.scss";

.activeImage {
  background: $primary;
  color: $body-bg;
}

.selectedImage {
  background: $primary_alt;
  color: $body-bg;
}
