.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  color: #333;
}

.errorMessage {
  font-size: 18px;
  margin-bottom: 5px;
}

.suggestion {
  font-size: 16px;
}

.homeLink {
  color: #f56969;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}