@import 'src/config';

.container {
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/backgrounds/LinearGradientBackground01.svg") no-repeat center center fixed;
  background-size: cover;
}


.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #67237E;
    background: #FFFFFF;
    margin: 0;
    padding: 0;
    height: 8vh;

    @media (max-width: 768px) {
      height: 6vh;
    }

}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-left: 8vw;
  color: #67237E;

  &:hover {
    color: #67237E;
    text-decoration: none;
  }
}

.logoIcon {
  display: flex;
    width: 5vh;
    height: 5vh;
    margin: 0;
    padding: 0;
}

.logoText {
  display: flex;
  font-size: 2.8vh;
  font-weight: 600;
  margin: 0;
  padding-left: 1vh;
}

.buttonContainer {
  margin-right: 8vw;
  padding: 2vh 4vw;
  background: #6C74B4;
  border-color: #6C74B4;

  &:hover, &:focus, &:active {
    background: #8084b4 !important;
    border-color: #8084b4 !important;
    box-shadow: 0 0 0 0.2rem #8084b4 !important;
  }

  @media (max-width: 768px) {
    padding: 1.3vh 4vw;
  }

  @media (max-width: 576px) {
    padding: 1vh 4vw;
  }

  @media (max-height: 576px) {
    padding: 0.5vh 4vw;
  }
}

.mainContainer {
  display: flex;
  justify-content: center;
}

.previewContainer {
  position: relative;
  flex-shrink: 0;
  &:before, &:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
  }
  &:before {
    box-shadow: -15px 0 15px -10px inset;
    left: -15px;
  }
  &:after {
    box-shadow: 15px 0 15px -10px inset;
    right: -15px;
  }
}

.overflowContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../../assets/images/backgrounds/launch.jpg") no-repeat center center;
  background-size: cover;
}

.leftSection {
  width: 100%;
  max-width: 25vw;
  margin: 2vw;

  @media (max-width: 1200px) {
    max-width: 30vw;
  }

  @media (max-width: 992px) {
    max-width: 40vw;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.leftSectionMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  background: #272441;
  z-index: 5;
  padding: 3rem 2rem;
}

.rightSection {
  width: 100%;
  max-width: 25vw;
  margin: 2vw;

  @media (max-width: 1200px) {
    width: 5vw;
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.infoIconContainer {
  display: none;
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  z-index: 10;
  color: white;

  @media (max-width: 768px) {
    display: block;
  }
}

.infoIcon {
  width: 2rem;
  height: 2rem;
  background: #272441;
  border-radius: 50%;
}


