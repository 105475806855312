.listUsedByBooksBox {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    overflow-y: auto;
}

.listChaptersBox-page {
    height: 40vh;
}

.imgPromptCover {
    width: auto;
    max-height: 40vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.imgPromptCover-col {
    overflow: hidden;
}

