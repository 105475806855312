@import "../../../../config";

.ConfirmTermModal {
  z-index: 999999;
  background-color: rgba($black, .6);

  .mainPageLinks {
    color: $primary !important;
  }
}


