@import "src/config";

.row {
  display: flex;
  justify-content: flex-end;
  :hover {
    background: $gray-100;
  }
}

.row:hover {
  background: $gray-100;
}

.actions {
  text-align: right;
  border: 1px solid $gray-200;
}

.btnImg {
  height: 15px;
}

