@import "../../../config.scss";

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding-inline-start: 0 !important;
  width: 100%;
  margin: 5px 0px;

  .pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin: auto 4px;
    color: $secondary;
    box-sizing: border-box;
    align-items: center;
    border-radius: 0.25rem;
    font-size: 1rem;
    min-width: 2rem;
    border: 1px solid $secondary;

    @media (max-width: 870px) {
      &:first-child,
      &:last-child {
        display: none;
      }
    }

    &.dots {
      border: none;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: $primary;
      cursor: pointer;
    }

    &.selected {
      background-color: $secondary;
      color: $darkRed;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-right: 0.12em solid $primary;
        border-top: 0.12em solid $primary;
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid $primary;
        border-top: 0.12em solid $primary;
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}