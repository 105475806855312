.circle .cropper-crop-box,
.circle .cropper-view-box {
    border-radius: 50%;
}

.circle .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.circle .cropper-face {
    background-color:inherit !important;
}

.circle .cropper-dashed,
.circle .cropper-point.point-se,
.circle .cropper-point.point-sw,
.circle .cropper-point.point-nw,
.circle .cropper-point.point-ne,
.circle .cropper-line {
    display:none !important;
}

.circle .cropper-view-box {
    outline:inherit !important;
}
