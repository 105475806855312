@import "src/config";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.submitButton {
  color: black;
  border-color: gray;
  background: white;
  padding: 0.7rem;
  width: 100%;
  box-shadow: 0 0 0 0 gray;
  transition: all 0.3s ease;
  border-radius: 50px;
}

.submitButton:hover {
  background: #f6f6f6;
  box-shadow: 0 0 2px 0 gray;
}

.submitButton:active {
  background: #f6f6f6;
  transform: $button-active-transform;
}

.submitButton:visited {
  background: #f6f6f6;
}

.submitButton:focus {
  background: #f6f6f6;
  box-shadow: 0 0 0 0 gray;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1px 12px;
}

.logoWrapper {
  position: absolute;
  left: 5px;
}

/* Add styles for disabled state */
.submitButton[disabled],
.submitButton[disabled]:hover,
.submitButton[disabled]:active
{
  cursor: not-allowed; 
  pointer-events: none; 
  transform: none;
  box-shadow: 0 0 0 0 gray;
}