@import "config";
.textHeader {
  color: $danger;
}

.text {
  text-align: left;
  margin-top: 1rem;
}

.highlight {
  color: $danger;
  font-weight: bold;
}

.accent {
  color: $blue;
}

.dropHere {
  margin-top: 1rem;
}
