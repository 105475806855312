.commentsList {
    position: relative;
}

.commentsList .card-title {
    font-size: .9em;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding-bottom: .75em;
    padding-top: .75em !important;
}

.boxLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(255, 255, 255, .03);
}

.boxSpinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
