@import "src/config";

.dropOffContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5px;
  line-height: 1;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
}

.dropOff {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  border: 3px solid white;
  margin: 6px auto;
  position: relative;
}

.dropOff-span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  width: auto;
  white-space: nowrap;
}

.branchAnalytics {
  position: absolute;
  text-align: left;
  width: max-content;
  min-width: 200px;
  padding: 10px;
  background-color: $gray-200;
  color: black;
  z-index: 10;
}

.decisionPointRow {
  width: 100%;
}

.decisionPointCol {
  display: flex;
  justify-content: flex-end;
}

.decisionPointCount {
  text-align: left;
  padding-left: 4px;
}

.decisionPointIcon {
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

