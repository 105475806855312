.PFR-DatePicker {
  .btn,
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.PF-td-users {

  .users {
    &:before {
      content: ', ';
    }

    &:first-child:before {
      content: '';
    }
  }
}

.PFR-DatePicker-box {
  position: relative;
  padding-right: 3em;

  .PFR-DatePicker-close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 2em;
    width: 2.2em;
    height: 2.2em;
    line-height: 2em;
    padding: 0 !important;
    font-size: .75em;

    span {
      font-size: 2em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -55%);
      line-height: .5em;
    }
  }
}
