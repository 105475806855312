@import "../../../config";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,500;0,600;0,800;1,400&display=swap');

.HomePage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: calc((100vh - 720px) / (1360 - 720) * (19 - 10) + 10px);
  background: rgba($primary_alt, 1) top center no-repeat;
  //background-image: none !important;
  background: -moz-linear-gradient(top, rgba($gradient_1, 1) 0%, rgba($gradient_2, 1) 45%, rgba($gradient_3, 1) 100%);
  background: -webkit-linear-gradient(top, rgba($gradient_1, 1) 0%, rgba($gradient_2, 1) 45%, rgba($gradient_3, 1) 100%);
  background: linear-gradient(to bottom, rgba($gradient_1, 1) 0%, rgba($gradient_2, 1) 45%, rgba($gradient_3, 1) 100%);
  font-family: 'Montserrat', sans-serif;
}

@media (max-width: 1024px) {
  .HomePage {
    font-size: calc((100vw - 320px) / (992 - 320) * (12 - 7) + 7px);
  }
}

/****/

.pageHeader.pageHeader-home {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  height: 4em;
  background: linear-gradient(to bottom, rgba(247, 163, 151, 1) 0%, rgba(247, 163, 151, 0) 100%);
}

.pageHeader-home .navbar {
  padding: 0;
}

.pageHeader-home .navbar .container {
  max-width: 81%;
  min-width: 72em;
  position: relative;
}

@media (max-width: 992px) {
  .pageHeader-home .navbar .container {
    max-width: 100%;
    min-width: 90%;
  }
}

.pageHeader-home .site-logo {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: .9em;
  width: 2.1em;
  height: 2.1em;
  color: $white;
}

@media (max-width: 992px) {
  .pageHeader-home .site-logo {
    left: 1.2em;
  }
}

.pageHeader-home .site-logo svg,
.pageHeader-home .site-logo img {
  width: 100%;
}

.mainPageNav {
  color: $white;
  position: absolute;
  top: 1.2em;
  right: 0;
  font-size: 1.5em;
}

@media (max-width: 992px) {
  .mainPageNav {
    width: 70%;
    display: block;
    text-align: right;
    padding-right: 1.2em;
  }
}

.pageHeader-home .mainPageLinks {
  color: $white;
  text-transform: uppercase;
  padding: 0;
  text-decoration: none;
}

.pageHeader-home .mainPageLinks:hover {
  color: $white !important;
  text-decoration: none !important;
}

/****/

.HomePageFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: 2.2em 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 100%);
}

.HomePage .HomePageFooter .btn {
  color: $dark;
  font-size: 1.2em;
  text-decoration: underline;
  position: relative;
}

.HomePage .HomePageFooter .btn:before {
  content: '';
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translate(-50%, -50%);
  border-left: 1px solid $dark;
  height: 50%;
}

.HomePage .HomePageFooter .btn:first-child:before {
  display: none;
}

.HomePage .HomePageFooter .btn:hover {
  text-decoration: none;
}

.HomePage .HomePageFooter .btn.active {
  text-decoration: underline;
}

/***/

.HomePageFooterBg {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: transparent url("../../../assets/images/clouds01.svg") center top;
  background-size: auto 25em;
  height: 21em;
}

.HomePageFooterBg:before {
  content: '';
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background: transparent url("../../../assets/images/clouds02.svg") center top;
  background-size: auto 25em;
  height: 22.5em;
}

.HomePageFooterBg:after {
  content: '';
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background: transparent url("../../../assets/images/clouds04.svg") center top;
  background-size: auto 26em;
  height: 22em;
}


@media (max-width: 1024px) {
  .HomePageFooterBg {
    background-size: auto 15em;
    height: 12em;
  }

  .HomePageFooterBg:before {
    background-size: auto 15em;
    height: 13.5em;
  }

  .HomePageFooterBg:after {
    background-size: auto 16em;
    height: 13em;
  }
}

/****/

.mainPage-content {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 9;
  top: 150vh;
  opacity: 0;
  transition: top 1.5s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.5s cubic-bezier(0.685, 0.0473, 0.346, .5);
}

.mainPage-content.active {
  opacity: 1;
  top: 0;
}

.mainPage-content-wrapper {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  /* min-height: 72em; */
}

@media (max-width: 1024px) {
  .mainPage-content-wrapper {
    height: auto;
    min-height: auto;
  }
}

.slide-1 {
  width: 80%;
  max-width: 72em;
  padding: 11em 0 0 0;
}

@media (max-width: 1024px) {
  .slide-1 {
    width: 90%;
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  .slide-1 {
    padding: 7em 0;
  }
}

.slide-2 {
  width: 33em;
  padding: 5.1em 0 7em 0;
}

.slide-3 {
  width: 33em;
  padding: 5.1em 0 7em 0;
}

.slide-4 {
  width: 33em;
  padding: 5.1em 0 7em 0;
}

.slide-5 {
  width: 66em;
  padding: 5.1em 0 11em 0;
}

.slide-7 {
  width: 90%;
  max-width: 40em;
  padding: 8.1em 1.5em 11em 1.5em;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .slide-7 {
    max-width: 35em;
  }
}

@media (max-width: 1024px) {
  .slide-5 {
    max-width: 90%;
  }
}

.slide-1 .videoYouTube-Box {
  margin: 0 auto;
  overflow: hidden;
  width: 50.3em;
  height: 28.5em;
  position: relative;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .slide-1 .videoYouTube-Box {
    width: 100%;
    height: 0;
    padding-top: 56.2%;
  }

  .videoYouTube-img-box {
    top: 0;
    position: absolute !important;
  }

  .videoYouTube-img-box img {
    top: 0;
    position: absolute !important;
    height: auto !important;
  }
}

.videoYouTube {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.videoYouTube-img-box,
.videoYouTube-img-box img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
  transition: z-index .3s cubic-bezier(0.685, 0.0473, 0.346, 1),
  opacity .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  opacity: 1;
}

.videoYouTube-Box.play .videoYouTube-img-box {
  z-index: 1;
  opacity: 0;
}

.videoYouTube-nav {
  width: 4.5em;
  height: 3.5em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background: rgba(0, 0, 0, .3);
  border-radius: .5em;
  display: none;
}

.videoYouTube-nav img {
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.videoYouTube-Box.null .videoYouTube-nav.play {
  display: block;
}

.videoYouTube-Box.pause .videoYouTube-nav.pause {
  display: block;
}

.videoYouTube-Box.end .videoYouTube-nav.end {
  display: block;
}

.slide-1 .YouTube-text-1 {
  margin: 0;
  padding: .65em 0 .25em 0;
  font-size: 2.32em;
  font-weight: 900;
  text-align: center;
  line-height: 1;
  color: $white;
}

@media (max-width: 560px) and (orientation: portrait) {
  .slide-1 .YouTube-text-1 {
    font-size: 1.92em;
  }
}

@media (max-width: 380px) and (orientation: portrait) {
  .slide-1 .YouTube-text-1 {
    font-size: 2.34em;
  }
}

.slide-1 .YouTube-text-2 {
  margin: 0;
  padding: 1.35em 0;
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  line-height: 1;
  color: #FFFFFF;
}

@media (max-width: 480px) and (orientation: portrait) {
  .slide-1 .YouTube-text-2 {
    padding: 1.35em 0 1em 0;
  }
}

.btnMainPage {
  color: $white;
  text-transform: uppercase !important;
  font-weight: 600;
  font-size: 1.55em;
  border-radius: .3em;
  padding-left: 2.6em;
  padding-right: 2.6em;
  background-color: #FF6251;
  border: .2em solid #FF6251;
  border-bottom-color: #DC5748;
  margin: 1.4em auto;
}

.WriteWithUs-page {
  .btnMainPage {
    white-space: nowrap;
    padding-left: 1em;
    padding-right: 1em;
  }
}


@media (max-width: 380px) and (orientation: portrait) {
  .btnMainPage {
    margin: 1em auto;
  }
}

.btnMainPage:hover {
  background-color: #DC5748;
}

.slide-2 .btnMainPage {
  margin: .15em auto;
}

.HomePage .backBtn,
.HomePage .closeBtn {
  color: $white !important;
  background-color: #FFC097 !important;
  border-color: #FFC097 !important;
  border-radius: 2em;
  padding: 0.5em 0.65em !important;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 6em;
  z-index: 999;
  font-size: 1.1em;
}

.HomePage .backBtn:hover,
.HomePage .closeBtn:hover {
  background-color: #FF6251 !important;
  border-color: #FF6251 !important;
}

.HomePage .backBtn {
  right: auto;
  left: 0;
}

.HomePage .modal-title {
  color: #fff !important;
  font-weight: 800;
  border-bottom: 1px solid #fff;
  margin-bottom: .6em;
  padding-top: .15em !important;
  padding-bottom: .25em !important;
  font-family: "Montserrat", sans-serif;
  font-size: 2.9em;
}

.HomePage .modal-title small {
  display: block;
  font-size: .4em;
}

.mainPageLinks {
  font-weight: 600;
  color: $white !important;
  text-decoration: underline !important;
  position: relative;
}

.pageDocuments {
  .mainPageLinks {
    color: $primary !important;
  }
}

.CongratulationsContent {
  h2 {
    font-size: 1.4em;
  }

  .mainPageLinks {
    color: $dark !important;
  }
}

.mainPageLinks-nav .mainPageLinks {
  color: #FF6251 !important;
}

@media (max-width: 960px) {
  .mainPageLinks-nav .mainPageLinks {
    font-size: 1.2em;
  }
}

.mainPageLinks:hover {
  text-decoration: none !important;
  color: #DC5748 !important;
}

.mainPageLinks-nav .mainPageLinks:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-left: 2px solid #FF6251 !important;
  height: 75%;
}

.mainPageLinks-nav .mainPageLinks:first-child:before {
  display: none;
}

.mainPageLinks-nav .mainPageLinks {
  margin: 0 !important;
  padding: 0 .5em !important;
}


.mt-input {
  position: relative;
  padding-top: 1.75em;
  margin-bottom: .35em !important;

  .form-label {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0 .5em .5em .25em;
    font-size: 1.5em;
    background: transparent;
    line-height: 1;
    border-radius: .35em;
    color: $white;
    font-weight: 800;
  }

  .text-muted {
    &.form-text {
      color: $white !important;
      font-weight: 800;
      text-align: right;
      margin-bottom: .5em;
    }
  }
}

.loginOnPreview .mt-input .form-label {
  color: $dark;
  font-size: 1em;
  font-weight: 400;
  padding: .5em .5em 0 .25em;
}

.mainPage .invalid-feedback {
  font-size: 1em;
  margin: .05em 0 !important;
}

.mainPage-right-box .backBtn {
  max-width: 5em;
}

.mainPage-right-box .backBtn,
.mainPage-right-box .closeBtn,
.mainPage-right-form-box .closeBtn {
  color: $white !important;
  background-color: #FFC097 !important;
  border-color: #FFC097 !important;
  border-radius: 2em;
  padding: 0.5em 0.65em !important;
  line-height: 1;
  position: absolute;
  right: 0;
  top: .5em;
  z-index: 999;
  font-size: 1.3em;
}


.HomePage .custom-control-label {
  font-size: 1.2em;
  line-height: 1;
  margin-bottom: 1em;
}

@media (max-width: 960px) {
  .HomePage .custom-control-label {
    font-size: 1.4em;
    line-height: 1.2;
  }
}

.HomePage .custom-control-label:after,
.HomePage .custom-control-label:before {
  top: 0;
}

.HomePage .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: $dark !important;
}

.HomePage .custom-control-label:before {
  border-color: $white;
}

.HomePage .form-control {
  border-color: $white;
  border-bottom-width: 2px;
  padding: .375em .75em !important;
  font-size: 1.2em !important;
  border-radius: 0 !important;
  height: 2.5em;
}

.badge-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    height: 60px;
    width: auto;
  }

  svg {
    height: 40px;
    width: 120px;
  }
}

.HomePage .was-validated .custom-control-input:valid ~ .custom-control-label:before, {
  border-color: #218C74;
}

.HomePage .was-validated .form-control:valid {
  border-color: $white;
  border-bottom-color: #218C74;
}

.HomePage .was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: $success !important;
}

.HomePage .was-validated .form-control:invalid {
  border-color: $white;
  border-bottom-color: #B33939;
}

.HomePage .text-danger,
.HomePage .invalid-feedback {
  color: #B33939 !important;
}

.HomePage .form-control.is-valid,
.HomePage .was-validated .form-control:valid,
.HomePage .was-validated .form-control:invalid {
  background-image: none !important;
}

.mainPage-right-form-box-content .form-row {
  margin: 0 !important;
}

.passEyeBtn {
  position: absolute;
  top: 0;
  right: 0;
  height: 2.7em;
  width: 2.4em;
  cursor: pointer;
}

.passEyeBtn img {
  width: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.passwordTooltip {
  right: 0;
  text-align: left;
  min-width: 5em;
}

.HomePage .tooltip {
  display: none;
}

.HomePage .tooltip.show {
  display: block;
}

.passwordTooltip.tooltip.show {
  opacity: 1;
}

.passwordTooltip ul {
  padding-left: 1.5em;
}

.passwordTooltip .tooltip-inner {
  background: #FFAE93;
  text-align: left;
  min-width: 21em;
  font-size: .9em;
  font-weight: 600;
}

.passwordTooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #FFAE93;
  left: .5em;
}

/******/

.mainPage-left-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 50vw;
  height: 100vh;
}

.mainPage-img-right-box {
  position: fixed;
  right: 0;
  top: 0;
  width: 50vw;
  height: 100vh;
}

.active-rightBox-2,
.active-rightBox-3,
.active-rightBox-4 {
  .mainPage-left-box,
  .mainPage-img-right-box {
    display: none;
  }
}

.frontImgBox {
  width: 100%;
  height: 100%;
  background: transparent center bottom no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 0;
  opacity: 0;
}

.frontImgBox.FrontImg001 {
  right: 100vw;
  transition: right 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.frontImgBox.FrontImg002 {
  right: 100vw;
  transition: right 1.15s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.frontImgBox.FrontImg001.active,
.frontImgBox.FrontImg002.active {
  opacity: 1;
  right: 0;
}

.frontImgBox.FrontImg003 {
  left: 100vw;
  transition: left 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.frontImgBox.FrontImg004 {
  left: 100vw;
  transition: left 1.15s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.frontImgBox.FrontImg003.active,
.frontImgBox.FrontImg004.active {
  left: 0;
  opacity: 1;
}

.FrontImg001 img {
  transition: right 1.15s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
  position: absolute;
  right: 22vw;
  bottom: 5em;
  transform: translate(0, 0);
  width: 35em;
  height: auto;
}

.FrontImg002 img {
  transition: right 1.15s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
  position: absolute;
  right: 11vw;
  bottom: 5em;
  transform: translate(0, 0);
  width: 35em;
  height: auto;
}

.FrontImg003 img {
  transition: left 1.15s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
  position: absolute;
  left: 17vw;
  bottom: 5em;
  transform: translate(0, 0);
  width: 35em;
  height: auto;
}

.FrontImg004 img {
  transition: left 1.15s cubic-bezier(0.685, 0.0473, 0.346, 1), opacity 1.55s cubic-bezier(0.685, 0.0473, 0.346, 1);
  position: absolute;
  left: 5vw;
  bottom: 5em;
  transform: translate(0, 0);
  width: 35em;
  height: auto;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .FrontImg001 img {
    right: 14vw;
  }
  .FrontImg003 img {
    left: 14vw;
  }
  .FrontImg002 img {
    right: -3vw;
  }
  .FrontImg004 img {
    left: -3vw;
  }
}

@media (orientation: portrait) and (max-width: 1024px) {
  .HomePage.active-rightBox-2 .frontImgBox.FrontImg001.active {
    right: 100vw;
  }
  .HomePage.active-rightBox-2 .frontImgBox.FrontImg003.active {
    left: 100vw;
  }

  .HomePage.active-rightBox-2 .FrontImg001 img {
    right: 14vw;
  }
  .HomePage.active-rightBox-2 .FrontImg003 img {
    left: 14vw;
  }
  .HomePage.active-rightBox-2 .FrontImg002 img {
    right: 14vw;
  }
  .HomePage.active-rightBox-2 .FrontImg004 img {
    left: 14vw;
  }
}

@media (orientation: portrait) and (max-width: 660px) {
  .HomePage.active-rightBox-2 .frontImgBox.FrontImg001.active,
  .HomePage.active-rightBox-2 .frontImgBox.FrontImg002.active {
    right: 100vw;
  }
  .HomePage.active-rightBox-2 .frontImgBox.FrontImg003.active,
  .HomePage.active-rightBox-2 .frontImgBox.FrontImg004.active {
    left: 100vw;
  }
}

@media (orientation: portrait) and (max-width: 560px) {
  .frontImgBox.FrontImg001.active {
    right: 100vw;
  }
  .frontImgBox.FrontImg003.active {
    left: 100vw;
  }
}

@media (orientation: landscape) and (max-width: 960px) {
  .FrontImg001 img, .FrontImg002 img, .FrontImg003 img, .FrontImg004 img {
    width: auto;
    height: 70vh;
  }
  .FrontImg001 img {
    right: 28vw;
  }
  .FrontImg002 img {
    right: 19vw;
  }
  .FrontImg003 img {
    left: 28vw;
  }
  .FrontImg004 img {
    left: 18vw;
  }
}

#popover-contact .btn-link {
  font-size: .8em;
  color: #343a40 !important;
}

.mainPage-AlertBox {
  position: fixed;
  left: 50%;
  top: .5em;
  width: 70%;
  max-width: 50em;
  transform: translate(-50%, 0);
  z-index: 999;
  text-align: center;
}

.CongratulationsContent {
  text-align: left;
  font-size: 1.2em;
  font-weight: 400;
  padding: 1.5em 2.5em;
  margin: 0;
  line-height: 1.4em;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 0 7px $primary;
}

.CongratulationsContent p {
  margin: 0;
  padding: .5em 0;
}
