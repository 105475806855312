.title {
  font-size: 2em;
}
.prizeContainer {
  border: 1px dashed black;
  padding: 20px;
  border-radius: 20px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
}