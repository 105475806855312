@import "src/config";


.container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.logoSectionButton {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
}

.logoSectionButton:focus {
  border: 0;
  outline: none;
}


.logoSection {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
  background: $theme-secondary-background;
  height: 10rem;
  
  h1 {
    color: $theme-secondary-color;
    font-weight: 700;
    margin: 0;
    padding: 0;
    align-self: center;
  }
  
  svg {
    align-self: center;
    margin-right: 16px;
  }
}

@media (max-width: 768px) {
  .logoSection {
    margin-bottom: 0;
    height: 5rem;

    h1 {
      color: $theme-secondary-color;
      margin: 0;
      padding: 0;
      align-self: center;
      font-size: 1.5rem;
    }

    svg {
      align-self: center;
      margin-right: 16px;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}