@import "../../../config";

.header {
  margin: 10px;
  padding: 10px;
  text-align: center;
  background-color: lighten($secondary,10);
  border-radius: 5px;
}

.title {
  text-align: center;
  margin: auto;
}

.body {
  justify-content: center;
}

.button {
  min-width: 90px;
  margin-left: 10px;
}
