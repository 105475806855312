.NodePositioningModal {
    position: fixed;
    top: 5.5em;
    right: 1em;
    max-width: 20em;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel.preview-panel .NodePositioningModal {
    right: 31em;
}