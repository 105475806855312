.name {
  min-width: 150px;
  padding: 0 1px;
  margin: 1px 1px;
}

.type {
  min-width: 110px;
  max-width: 110px;
  padding: 0 1px;
  margin: 1px 1px;
}

.experiment {
  min-width: 140px;
  padding: 0 1px;
  margin: 1px 1px;
}

.path {
  min-width: 140px;
  padding: 0 1px;
  margin: 1px 1px;
}

.dataType {
  min-width: 95px;
  max-width: 95px;
  padding: 0 1px;
  margin: 1px 1px;
}

.value {
  min-width: 110px;
  max-width: 110px;
  padding: 0 1px;
  margin: 1px 1px;
}

.actions {
  text-align: right;
  min-width: 115px;
  max-width: 115px;
  padding: 0 1px;
  margin: 0 1px;
}
