.cropper-source {
  height: 512px;
  width: 100%;
}

.cropper-source-area {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.cropper-point {
  height: 15px !important;
  width: 15px !important;
  border-radius: 10px;
  background-color: #ff341e !important;
}

.cropper-view-box {
  outline: 2px solid #ff341e !important;
}