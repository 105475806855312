@import "src/config";


.submitButton {
  color: $theme-primary-btn-color;
  border-color: $theme-primary-btn-background;
  background: $theme-primary-btn-background;
  padding: 0.7rem;
  width: 75%;
  box-shadow: 0 0 0 0 $theme-primary-btn-background;
  transition: all 0.3s ease;
}

.submitButton:hover {
  color: lighten($theme-primary-btn-color, 3);
  border-color: lighten($theme-primary-btn-background-hover, 3);
  background: lighten($theme-primary-btn-background-hover, 3);
  box-shadow: 0 0 2px 0 $theme-primary-btn-background;
}

.submitButton:active {
  border-color: darken($theme-primary-btn-background-hover, 5);
  background: darken($theme-primary-btn-background-hover, 5);
  transform: $button-active-transform;
}

.submitButton:visited {
  border-color: $theme-primary-btn-background-hover;
  background: $theme-primary-btn-background-hover;
}

.submitButton:focus {
  border-color: darken($theme-primary-btn-background-hover, 15);
  background: darken($theme-primary-btn-background-hover, 15);
  box-shadow: 0 0 0 0 $theme-primary-btn-background;
}

.submitButton[disabled],
.submitButton[disabled]:hover,
.submitButton[disabled]:active
{
  cursor: not-allowed;
  pointer-events: none;
  transform: none;
  box-shadow: 0 0 0 0 gray;
}