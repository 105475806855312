@import "../../../config.scss";

$cropper-preview-height: 300px;
$preview-area-phone-height: 83%;
$preview-area-phone-width: 55%;
$preview-area-phone-margin-bottom: 14%;
$img-preview-offset-left: -23px;
$img-preview-offset-right: -22px;


$phone-height: $cropper-preview-height;
$phone-width: $phone-height*0.4733;

.cropper {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  margin: 6px 0 6px 0;

  .cropper-source {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    height: $cropper-preview-height;
    width: 100%;
    border: 1px dashed $secondary;
    padding: 5px;

    .cropper-source-area {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      width: 100%;
      max-width: 80%;

      .cropper-container {
        height: 100%;
      }
    }
  }

  .cropper-preview {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    height: $cropper-preview-height;
    padding: 5px;
    border: 1px dashed $secondary;

    .img-preview {
      height: 100%;
      width: 100%;
      margin: 0 auto;

      &.img-character-left {
        transform: translate($img-preview-offset-left, 0);
      }

      &.img-character-right {
        transform: scaleX(-1) translate($img-preview-offset-right, 0);
      }
    }

    .preview-area-phone-image {
      position: absolute;
      height: $phone-height;
      width: $phone-width;
      background: transparent url('../../../assets/images/preview/phone-preview.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .cropper-preview-left,
    .cropper-preview-right {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: $phone-height;
      width: $phone-width;
    }

    .preview-area-phone-left,
    .preview-area-phone-right {
      overflow: hidden;
      height: 87%;
      margin-bottom: $preview-area-phone-margin-bottom;

      &.character {
        height: $preview-area-phone-height;
      }

      .preview-area-phone-wrapper {
        height: 100%;
        width: 500%;
        position: relative;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -100%);
      }

      .preview-area-phone-dialog {
        width: 69px;
        height: 47px;
        position: relative;
        bottom: 4.15em;

        .img-dialog {
          width: 100%;
          height: 100%;
        }
      }
    }

    .preview-area-phone-left {

      .preview-area-phone-dialog {
        display: block;
        float: left;
        left: 0.5em;
      }
    }

    .preview-area-phone-right {

      .preview-area-phone-dialog {
        display: block;
        float: right;
        right: 0.5em;

        .img-dialog {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.cropper-area-btn {

  min-height: 1em;

  .cropper-source-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;


    button {
      margin: 5px 25px 5px 25px;
      min-width: 130px;
      min-height: 2rem;
      border-radius: 25px;

    }

    .cropper-source-btn-position {
      width: 100%;
    }

    .cropper-source-btn-scale {
      width: 100%;

      .cropper-source-btn-scale--text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $primary;
        font-weight: bold;
      }
    }
  }


}

.upload-image-form-submit-btn {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 1em 0.5em 1em;
  }
}