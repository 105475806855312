@import "src/config";

.formAlert {
  margin: 0 0 1rem 0;
  width: 100%;
}

.formTitle {
  text-align: center;
}

.formText {
  text-align: center;
  padding: 1rem 0.7rem;
}

.formSubText {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.8rem;
  
  a {
    color: $theme-primary-btn-background;
  }
}

.logoutButton {
  margin-top: 1rem;
  background: $theme-warning-btn-background;
  
  &:hover, &:focus {
    background: $theme-warning-btn-background-hover;
  }
}