.avatarImageCropContainer {
  height: 600px;

  .avatarImageCropper{
    .cropper-view-box {
      border-radius: 50%;
    }

    .cropper-container {
      height: 600px;
    }
  }
}
