.modal-body {
  overflow: visible;
}

.search-user-area {
  display: flex;
  justify-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.search-user-btn {
  margin: 0px 5px;
  min-width: 80px;
}

.rbt {
  width: 100%;
}

