@import "src/config";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #332E51 0%, #595287 100%);
  overflow: hidden;
  color: white;
  position: absolute;
  bottom: -100%;
  animation: openAnimation 0.5s ease-in-out 0.5s forwards;
}

@keyframes openAnimation {
  100% {
    bottom: 0;
  }
}


.headerContainer {
  font-family: Lato, sans-serif;
  margin: 10vh 3vh 0 3vh;
  text-align: center;
  letter-spacing: 0em;;
}

.headerTitle {
  font-size: 3.7vh;
  font-weight: 600;
  line-height: 4.4vh;
}

.headerSubTitle {
  margin-top: 3vh;
  font-size: 2.6vh;
  font-weight: 400;
  line-height: 3.2vh;
}

.buttonContainer {
  margin-top: 3vh;
}

.button {
  border-radius: 5vh;
  background: #6C74B4;
  border-color: #6C74B4;
  font-size: 2.6vh;
  line-height: 3vh;
  height: 8vh;
  width: 30vh;

  &:hover, &:focus, &:active {
    background: #8084b4 !important;
    border-color: #8084b4 !important;
    box-shadow: 0 0 0 0.2rem #8084b4 !important;
  }
}

.buttonSubText {
  margin-top: 1vh;
  text-align: center;
  button {
    color: #99A4FF;
    border: none;
    outline: none !important;
  }

  button:hover, button:focus, button:active {
    color: #8084b4 !important;
    text-decoration: underline !important;
    border: none;
  }
}

.contentContainer {
  margin-top: 3vh;
  line-height: 3.5vh;
  font-size: 2vh;
}

.imageContainer {
  position: absolute;
  bottom: 0;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center bottom;
}
