@import "../../../../config";

.CoverBgImgBox {
  width: 100%;
  position: relative;
  height: 0;
  padding-top: 150%;
  background-color: rgba($black, .4);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.coverEditor-imgBox {
  position: relative;
  overflow: hidden;
}

.coverEditor-imgBox .avatarCanvas {
  top: 0;
}
