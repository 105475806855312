.storytags-content {
    padding: 2em 0;
}

.tagItem {
    display: inline-block;
    margin: .5em;
    padding: .5em 2.5em .5em 1em;
    border-radius: 2em;
    position: relative;
}

.tagItem.no-edit {
    padding: .5em 1em;
    background-color: #f8f9fa;
}

.tagItem .close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 1.5em;
    width: 1.5em;;
    height: 1.5em;
}

.tagItem .close .form-check-input {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.tagsBox {
    border: 1px solid #ced4da;
    border-radius: .2em;
    position: relative;
}

.tagsBox .tagItem {
    font-size: .9em;
}

.tagsBox small,
.tagsBox .w-auto.CustomSelect {
    display: inline-block;
    margin: .5em;
}
