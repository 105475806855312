@import "src/config";

.memoryCardContainer {
  position: relative;
  border: 1px solid $gray-500;
  border-radius: 5px;
  background-color: $gray-200;
  overflow: hidden;
  width: 33px;

  img {
    width: 100%;
    height: 2.5em;
    object-fit: cover;
    }
}

.memoryCardRegular {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
}

.memoryCardMore {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: $gray-main;
}

.memoryCardToggle {
  position: absolute;
    top: -25%;
    left: -15%;
    width: 50%;
    height: 70%;
    background-color: red;
    opacity: 0.8;
    transform: rotate(45deg);
}

.memoryCardToggleEye {
  position: absolute;
  top: -0.2rem;
  left: 0;
  font-size: 0.7rem;
  color: white;
}
