@import "src/config";

.listTile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.listTileColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: lighten($secondary,10);
  font-weight: bold;
}

.list {
  padding: 0;
  margin: 0;
}

.listItem {
  list-style: none;
  border-bottom: 1px dashed $secondary;
}

