@import "../../../config";

.wrapperTable {
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapperTableLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 98;
  background: rgba($body-bg, .6);
  left: 0;
  top: 0;
}

.wrapperTableSpinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.formEdit {
  position: relative;
  z-index: 99;
  box-shadow: 0 0 10px rgba($dark, 0.4);
  background: $body-bg;
  border-radius: 8px;
  border: 2px solid $primary !important;
}

.bg-yellow {
  background-color: $yellow;
}


ul.thumbnails.image_selector {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

ul.thumbnails.image_selector ul {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.thumbnails.image_selector li {
  margin: 0 12px 12px 0;
  float: left;
}

ul.thumbnails.image_selector li .thumbnail {
  padding: 6px;
  border: 2px solid $primary;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

ul.thumbnails.image_selector li .thumbnail img {
  -webkit-user-drag: none;
}

ul.thumbnails.image_selector li .thumbnail.selected {
  background: rgba($primary, .4);
}

ul.thumbnails.image_selector .card-body {
  font-size: .8em;
  padding: .5em;
}

.CharPreviewImg .card-img-top,
ul.thumbnails.image_selector .card-img-top {
  width: auto;
}

.PreviewImg-36 {
  width: 36px;
  height: 36px;
}

.groupCharacterTitle {
  font-size: 1em;
  padding: 1em .5em .5em .5em;
}
