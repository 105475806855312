.navBoxHelpSection {
    position: absolute;
    bottom: 2em;
    right: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-fab {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    z-index: 99;
    border-radius: 2.8rem;
    width: 2.8rem;
    min-width: 2.8rem;
    height: 2.8rem;
    margin: .5em auto;
    align-items: center;
    align-content: center;
    padding: 0;
}

.btn-fab svg {
    width: 1.5em;
    height: 1.5em;
}

.btn-fab:before,
.btn-fab:after {
    display: none !important;
}

.btn-fab.btn-brand1 {
    color: #ffffff;
    font-weight: 500;
}

.btn-fab.btn-sm {
    border-radius: 2rem;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
}

.btn-fab.btn-lg {
    border-radius: 3.5rem;
    width: 3.5rem;
    min-width: 3.5rem;
    height: 3.5rem;
}

.navBoxHelpSection .dropdown-menu.show {
    margin: -5.3em -.7em 0 0;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.pageFAQContent {
    max-height: 80vh;
    overflow: auto;
}
