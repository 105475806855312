.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.image {
  max-height: 400px;
  max-width: 100%;
}
.button {
  margin: 10px 20px;
  min-width: 120px;
}