@import "../../../../config";

.terms {
  .title {
    width: 100%;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .subtitle {
    font-weight: bold;
    text-align: center;
  }

  .section-title {
    font-weight: bold;
    margin-left: 2em;
  }

  .margin-left {
    margin-left: 2em;
  }

  &.ConfirmOriginal {
    h2 {
      font-size: 1.4em;
      text-align: center;
      padding-top: .5em;
      padding-bottom: .5em;
      font-weight: bold;
    }

    h3 {
      font-size: 1.2em;
      text-align: center;
      padding-top: .5em;
      padding-bottom: .5em;
      font-weight: bold;
    }

    p {
      margin: 0;
      padding: .5em 1.5em 1em 1.5em;
    }

    ul, ol {
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;

      li {
        margin: 0;
        padding-bottom: 1em;

        ul, ol {
          padding-top: 1em;
        }
      }
    }
  }
}

.modal-body-container {
  display: flex;
  justify-content: center;
  margin-top: 20px ;
}

.modal-footer-confirm {
  display: flex;
  flex-direction: column;
}

.modal-footer-confirm-agreement {
  text-align: center;
  width: 100%;
}

.book_card-container {
  width: 100%;
  background: $gray-100;
  border: none;

  .book_card-title {
    text-align: center;
    background: $secondary ;
    padding: 10px 0;
    border-radius: 20px;
  }

  .book_card-list_item {
    background: $gray-100;
    border: none;

    .book_card-list_item__title {
      font-weight: bold;
    }
    .book_card-list_item__text {
      color: $black;
      width: 100%;
    }

  }
}

.royalty-container {
  background-color: $secondary;
  border-radius: 5px !important;

  .royalty-title {
    font-weight: bold;
    color: $red;
  }

  .royalty-text {
    font-style: italic;
  }

  .royalty-text__revenue {
    color: $red;
    font-weight: bold;
  }
}

.confirm-buttons-container {
  display: flex;

  .confirm-button-confirm,
  .confirm-button-cancel{
    margin: 5px 10px 0 10px;
  }
}
