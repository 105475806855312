.errorAlert {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999999;
}

#errorsBox {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999999;
    height: 1px;
    display: block !important;
}
