.bottomButtons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.spinner {
  position: absolute;
  top: 15px;
  right: 15px;
}
