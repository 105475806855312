@import "../../../../config";

.listItem {
  padding: 5px;
  width: 258px;
  height: 190px;
  border: none;
  overflow: hidden;
  margin-left: 0 !important;
}
.cardImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $yellowDark;
}

.cardImage {
  width: 100px;
  height: 130px;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
}

.cardTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: $secondary;
  overflow: hidden;
  border: 1px solid $yellowDark;
  width: 100%;
  height: 30px;
}

.cardAlias {
  height: 20px;
  width: 248px;
  padding: 0 3px;
  text-align: center;
  background-color: lighten($secondary,5);
  font-size: 0.8rem;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
  transform: translate(0,-3px);
  /* ellipsis beginning of string */
  direction: rtl;

}
