@import "src/config";

.form {
  border: 1px solid $primary;
}

.modalHeader {
  margin: 10px;
  padding: 10px;
  text-align: center;
  background-color: lighten($secondary,10);
  border-radius: 5px;
}

.modalTitle {
  width: 100%;
}

.modalBody {
  display: flex;
  justify-content: center;
}

.formGroup {
  margin: 0 0 40px 0;
}

.formLabel {
  color: $primary;
  font-weight: bold;
  width: 100%;
  padding-left: 10px ;
  background-color: $gray-200;
}

.button {
  min-width: 90px;
  margin-left: 20px;
}
